<div class="seccion-resumen container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card">
                <div class="row p-3">
                    <div class="col-12 col-lg-10">
                        <app-breadcrumb subtitle_detalle="Secciones por Distrito" [estado]="_obtenerDatosService?.config?.estado"
                            [distrito]="rutas.migas?.distrito"
                            [distrito_param]="rutas.migas?.distrito_clave" lastLabel=" / Secciones"></app-breadcrumb>
                        <div class="texto font-regular">
                            <p>
                                El total de votos calculado y porcentaje que se muestran, se refieren a los votos
                                asentados en las Actas
                                PREP hasta el momento.
                            </p>
                            <p>
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No
                                obstante, al
                                considerar todos los decimales, suman 100%.
                            </p>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile [total_num]="resumen?.total?.total" [total_prc]="resumen?.total?.prc">
                </app-resumen-votacion-mobile>
                <div class="row px-3 pb-4">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <div class="container-fluid mx-3 d-none d-md-none d-xl-block">
                    <div class="row dis-flex pb-1 mt-2">
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollLeft()">
                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                        </div>
                        <div class="col-11">
                            <div #widgetsContent class="row dis-flex justify-s scrollH">
                                <div *ngFor="let tarjeta of fichas; let indx = index;"
                                    class="col-6 col-sm-4 col-md-3 col-lg-2 px-1">
                                    <div class="card-resumen">
                                        <div class="encabezado dis-flex w-100 py-2">
                                            <div class="dis-flex w-100">
                                                <div class="row w-100 dis-flex">
                                                    <img class="img-partido" *ngFor="let img of tarjeta.imgs"
                                                        [src]="'./assets/img/logos-partidos/' + img?.toLowerCase()">
                                                    <!--
                          <p class="pe p-0" *ngFor="let img of tarjeta.imgs" [ngStyle]="{
                                                            'background-image': 'url(./assets/img/logos-partidos/'+  img.toLowerCase() +')',
                                                            'background-size': 'contain',
                                                            'background-repeat': 'no-repeat',
                                                            'background-position': 'center'
                                                        }">
                          </p>
-->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cuerpo dis-flex bgGrisTenue p-2">
                                            <div class="w-50">
                                                <p>Votos</p>
                                                <p class="votos">
                                                    <strong>{{ tarjeta?.total }}</strong>
                                                </p>
                                            </div>
                                            <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                                <p>Porcentaje</p> 
                                                <p>{{ tarjeta?.porcentaje }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollRight()">
                            <i class="fas fa-chevron-right" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <!-- TARJETAS MOVIL -->
                <movil-listado-content [infoList]="fichas" [tipoTarjeta]="8"></movil-listado-content>
                <!--********************************************************************************************************************-->

                <app-resumen-votacion [acumulados_num]="resumen?.acumulados?.total"
                    [acumulados_prc]="resumen?.acumulados?.prc" [noregistradas_num]="resumen?.no_registradas?.total"
                    [noregistradas_prc]="resumen?.no_registradas?.prc" [nulos_num]="resumen?.nulos?.total"
                    [nulos_prc]="resumen?.nulos?.prc" [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"></app-resumen-votacion>

                <div class="container-fluid mt-4 d-none d-md-none d-xl-block">
                    <div class="row">
                        <div class="col-12 px-0">
                            <div class="">
                                <div class="table-responsive tabla-seccion max-500">
                                    <table class="table table-striped contenido">
                                        <thead class="titulos text-center">
                                            <tr>
                                                <th class="w120p sticky-cell">
                                                    <div class="inter-partido dis-flex">
                                                        <strong class="txBlanco dis-flex text-center">
                                                            Secciones
                                                        </strong>
                                                    </div>
                                                </th>
                                                <!-- <ng-container *ngFor="let itemHeader of seccion_table?.headers; let i = index"> -->
                                                <th *ngFor="let itemHeader of seccion_table?.headers; let i = index" class="cell-widht">
                                                    <div class="inter-partido dis-flex">
                                                        <ng-container>
                                                            <ng-container *ngIf="itemHeader?.text != ''" class="dis-flex">
                                                                <strong class="txBlanco dis-flex text-center" [innerHtml]="checkHeader(itemHeader?.text)">
                                                                </strong>
                                                            </ng-container>
                                                            <ng-container *ngIf="itemHeader?.text == ''">
                                                                <ng-container *ngFor="let img of itemHeader?.imgs">
                                                                    <img class="img-partido"
                                                                        [src]="_obtenerDatosService.pathImg + img?.toLowerCase()"
                                                                        alt="">
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                </th>
                                                <!-- </ng-container> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="filas text-center" *ngFor="let itemBody of seccion_table?.rows">
                                                <td class="sticky-cell w120p text-left px-3">
                                                    <a class="link-text"
                                                        [routerLink]="['/'+contiendaActual+'/'+_globalService.param_distrito+'/secciones/'+itemBody.param]">
                                                        {{itemBody?.text}} </a>
                                                </td>
                                                <td *ngFor="let itemVoto of itemBody.col" class="cell-widht">
                                                    {{itemVoto}}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--
  <div class="container-fluid mt-3 tabla-listanominal">
    <div class="row mt-4">
      <div class="col-6 pr-0">
        <p class="px20 txRosaIne">
          <strong>
            Participación ciudadana conforme a las Actas Contabilizadas
          </strong>
        </p>
      </div>
      <div class="col-6 pl-5">
        <p class="px20 txRosaIne">
          <strong>
            Participación ciudadana conforme a las Actas Contabilizadas
          </strong>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="max-500">
          <table class="table table-borderless px12 mb-0 text-center">
            <thead class="contenido">
              <tr class="titulos bgMorado txBlanco px12">
                <th>
                  Actas
                </th>
                <th>
                  Esperadas
                </th>
                <th>
                  Capturadas
                </th>
                <th>
                  Contabilizadas
                </th>
                <th class="no">

                </th>
                <th>
                  Participación ciudadana
                </th>
                <th>
                  Total de la Lista Nominal
                </th>
              </tr>
            </thead>
            <tbody class="max-500">
              <tr class="bgGrisTabla borde-abajo">
                <td>
                  <strong>Total</strong>
                </td>
                <td>
                  {{actas_participacion?.head_info?.trow?.c1}}
                </td>
                <td>
                  {{actas_participacion?.head_info?.trow?.c2}}
                </td>
                <td>
                  {{actas_participacion?.head_info?.trow?.c3}}
                </td>
                <td class="no">

                </td>
                <td>
                  {{actas_participacion?.head_info?.prow?.participacion}}
                </td>
                <td>
                  {{actas_participacion?.head_info?.trow?.total}}
                </td>
              </tr>
              <tr class="bgGrisTabla">
                <td>
                  <strong>Porcentaje</strong>
                </td>
                <td>
                  {{actas_participacion?.head_info?.prow?.c1}}
                </td>
                <td>
                  {{actas_participacion?.head_info?.prow?.c2}}
                </td>
                <td>
                  {{actas_participacion?.head_info?.prow?.c3}}
                </td>
                <td class="no">

                </td>
                <td colspan="2" class="px10">
                  (Porcentaje calculado considerando la votación en casillas especiales)<br>Total de votos/lista nominal
                  de Actas Contabilizadas = %Participación
                </td>
              </tr>
              <tr class="filas py-2"
                *ngFor="let itemSecciones of actas_participacion?.rows">
                <td>
                  {{itemSecciones.text}}
                </td>
                <td>
                  {{itemSecciones.c1}}
                </td>
                <td>
                  {{itemSecciones.c2}}
                </td>
                <td>
                  {{itemSecciones.c3}}
                </td>
                <td class="no">

                </td>
                <td>
                  {{itemSecciones.participacion}}%
                </td>
                <td>
                  {{itemSecciones.total}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div> -->


    <!-- <div class="row tabla-seccion mt-3">
        <div class="col-7">
            <div class="tabla card pb-3">
                <div class="contenido max-500">
                    <div class="container-fluid"> -->
    <!-- Títulos -->
    <!-- <div class="row bgMorado txBlanco text-center py-2">
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    <strong>
                                        Actas
                                    </strong>
                                </p>
                            </div>
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    <strong>
                                        Esperadas
                                    </strong>
                                </p>
                            </div>
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    <strong>
                                        Capturadas
                                    </strong>
                                </p>
                            </div>
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    <strong>
                                        Contabilizadas
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <div class="row subtitulos text-center borde-abajo py-2">
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    <strong>
                                        Total
                                    </strong>
                                </p>
                            </div>
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    {{_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.participacionCiudadana?.total?.esperadas}}
                                </p>
                            </div>
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    {{_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.participacionCiudadana?.total?.capturadas}}
                                </p>
                            </div>
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    {{_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.participacionCiudadana?.total?.contabilizadas}}
                                </p>
                            </div>
                        </div>
                        <div class="row subtitulos text-center py-2">
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    <strong>
                                        Porcentaje
                                    </strong>
                                </p>
                            </div>
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    {{_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.participacionCiudadana?.porcentaje?.esperadas}}%
                                </p>
                            </div>
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    {{_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.participacionCiudadana?.porcentaje?.capturadas}}%
                                </p>
                            </div>
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    {{_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.participacionCiudadana?.porcentaje?.contabilizadas}}%
                                </p>
                            </div>
                        </div> -->
    <!-- Filas -->
    <!-- <div class="row filas text-center" *ngFor="let itemSecciones of _obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.participacionCiudadana?.secciones">
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    {{itemSecciones?.actas}}
                                </p>
                            </div>
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    {{itemSecciones?.esperadas}}
                                </p>
                            </div>
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    {{itemSecciones?.capturadas}}
                                </p>
                            </div>
                            <div class="col-3 col-sm-3 dis-flex">
                                <p>
                                    {{itemSecciones?.contabilizadas}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-5">
            <div class="tabla card pb-3">
                <div class="contenido max-500">
                    <div class="container-fluid"> -->
    <!-- Títulos -->
    <!-- <div class="row bgMorado txBlanco text-center py-2">
                            <div class="col-6 col-sm-6 dis-flex">
                                <p>
                                    <strong>
                                        Participación ciudadana
                                    </strong>
                                </p>
                            </div>
                            <div class="col-6 col-sm-6 dis-flex">
                                <p>
                                    <strong>
                                        Total de la Lista Nominal
                                    </strong>
                                </p>
                            </div>
                        </div>
                        <div class="row subtitulos text-center borde-abajo py-2">
                            <div class="col-6 col-sm-6 dis-flex">
                                <p>
                                    {{_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.participacionCiudadana?.total?.participacion}}%
                                </p>
                            </div>
                            <div class="col-6 col-sm-6 dis-flex">
                                <p>
                                    {{_obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.participacionCiudadana?.total?.total}} (100.0000%)
                                </p>
                            </div>
                        </div>
                        <div class="row subtitulos text-center py-1">
                            <div class="col-12 dis-flex">
                                <p class="px10">
                                    (Porcentaje calculado considerando la votación en casillas especiales)<br>Total de votos/lista nominal de Actas Contabilizadas = %Participación
                                </p>
                            </div>
                        </div> -->
    <!-- Filas -->
    <!-- <div class="row filas text-center" *ngFor="let itemSecciones of _obtenerDatosService?.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.distritos['distrito'+ _globalService.rutaParametros.noDistrito]?.secciones?.participacionCiudadana?.secciones">
                            <div class="col-6 col-sm-6 dis-flex">
                                <p>
                                    {{itemSecciones?.participacion}}%
                                </p>
                            </div>
                            <div class="col-6 col-sm-6 dis-flex">
                                <p>
                                    {{itemSecciones?.total}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->




</div>
<app-estadistica-nacional
    [tipo_estadistica]="rutas.migas?.distrito"
    [short_tpl]="true"
    [short_data]="short_data">
</app-estadistica-nacional>