<div class="buscador card bgMorado txBlanco dis-flex space-c align-s pl-2 pr-2 h-100" [ngClass]="{'brdr': themeService.isDarkTheme()}">
    <div class="container-fluid bgPrototipo">
        <div class="row">
            <div class="col-12 px-0">
                <p class="px16">
                    Conoce los resultados de tu sección
                </p>
                <p class="px10 pl-1">
                    <i class="fas fa-question-circle px14 cursor-hand" aria-hidden="true" data-toggle="modal" data-target="#modalCasilla"></i> Para conocer los resultados, ingresa el número de tu sección.
                    <!-- <span *ngIf="_obtenerDatosService?.config?.estado == 'Veracruz'">Para conocer los resultados de tu sección, ingresa los siguientes datos.</span>
			        <span *ngIf="_obtenerDatosService?.config?.estado == 'Oaxaca'">Para conocer los resultados, ingresa el número de tu sección.</span> -->
                    <br>* Campo obligatorio
                </p>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <form #FormCasilla="ngForm" (ngSubmit)="buscaCasilla(FormCasilla)">
            <div class="row px-1">
                <div class="col-md-6 px-1">
                    <div class="form-group m-0">
                        <label class="m-0" for="seccion">Sección *</label>
                        <!-- <div class="input-group df-sw">
                            <div class="dropdown w-100 bgBlanco">
                                <select class="form-control select-entidad" 
                                id="seccion" name="seccion" ngModel required #seccion="ngModel" [ngClass]="{'no-border': !themeService.isDarkTheme()}" seccion>
                                    <option></option>
                                    <option [ngValue]="itemSeccion.seccion" *ngFor="let itemSeccion of arrSecciones">
                                        {{ itemSeccion.text }}
                                    </option>
                                </select>
                            </div>
                        </div> -->
                        <div class="input-group df-sw">
                            <div class="dropdown w-100 bgBlanco">
                                <input type="text" class="form-control"  min="0" (keyup)="onChangeEvent($event)" (change)="onChangeEvent($event)" pattern="\d{0,6}" inputmode="numeric" maxlength="6" digitOnly
                                id="seccion" name="seccion" ngModel required #seccion="ngModel" [ngClass]="{'no-border': !themeService.isDarkTheme()}" seccion>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-6 px-1">
                    <div class="form-group">
                        <label class="m-0 px14" for="primerApellido">Primer apellido</label>
                        <div class="input-group df-sw">
                            <div class="dropdown w-100 bgBlanco">
                                <input type="text" class="form-control" 
                                id="apellido" name="apellido" ngModel required #apellido="ngModel" [ngClass]="{'no-border': !themeService.isDarkTheme()}" apellido>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-6 px-1">
                    <div class="form-group">
                        <label class="m-0 px14" for="submit">&nbsp;</label>
                        <div class="input-group df-sw btn-consulta">
                                <button type="submit" class="btn btn-block px14 px-1 btn-rosa-buscador">
                                    Consulta
                                </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row px-1">
                <div class="col-md-12 px-1">
                    <div class="form-group">
                        <label class="m-0 px14" for="submit">&nbsp;</label>
                        <div class="input-group df-sw">
                            <button type="submit" class="btn btn-block px14 px-1 btn-rosa-buscador">
                                Consulta
                            </button>
                        </div>
                    </div>
                </div>
            </div> -->
        </form>
    </div>
</div>