<div class="base-datos">
    <div class="container-fluid contenedor-video txBlanco">
        <div class="row">
            <div class="col-12 px-3 py-5 dis-flex mobile-p">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-10 col-lg-8 cuadro-video dis-flex">
                            <i *ngIf="config?.url_video && !startVideo" class="cursorPointer fas fa-play-circle" (click)="onStartViedeo()"></i>
                            <i *ngIf="!config?.url_video" class="cursorPointer fas fa-play-circle"></i>
                            <!-- <iframe style="height:100%;width:100%;border:none;overflow:hidden;" [src]='safeURL' frameborder="0" allowfullscreen *ngIf="config && startVideo"></iframe> -->
                            <!-- <iframe style="height:100%;width:100%;border:none;overflow:hidden;" src="https://www.oplever.org.mx/wp-content/uploads/2022_/PREP.mp4" frameborder="0" allowfullscreen></iframe> -->

                            <video *ngIf="config?.url_video && startVideo" width="100%" height="100%" autoplay="true" controls>
                                <source [src]='safeURL' type="video/mp4">
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Definicion -->

    <div class="container-fluid py-5 definicion" id="baseutilidad">
        <div class="row">
            <div class="col-12">
                <div class="text-center titulo padding-b-25">
                    Definición
                </div>
                <div class="subtitulo padding-b-50 p-l">
                    - Base de Datos y utilidad -
                </div>
            </div>
            <div class=" offset-2 col-12 col-lg-7 p-l mobile-parrafo">
                <div>
                    La <span class="font-bold">base de datos</span> se refiere al conjunto de información perteneciente al <span class="font-bold">Programa de Resultados Electorales 
                    Preliminares</span>, ordenada de forma sistemática para su descarga y análisis.​ Es actualizada conforme al último corte. Permite realizar distintas operaciones ya que incluye datos numéricos, alfanuméricos y cronológicos.​
                </div>
            </div>
        </div>
    </div>

    <!-- Carpeta -->

    <div class="contenido container-fluid bgAzul txBlanco py-5 carpeta" id="contenido">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="text-center titulo pading-b-10">
                    Carpeta de archivos
                </div>

            </div>
            <div class="col-12 p-l">
                <div class="subtitulo">
                    - Contenido -
                </div>
                <div class="padding-b-40 mobile-parrafo">
                    Se trata de un archivo comprimido con extensión <span class="font-bold">.zip</span>, contiene:
                </div>
            </div>
            <div class="col-12 col-xl-5 p-l">
                
                <div class="padding-b-40 mobile-parrafo">
                    <p class="font-bold">&#8226; Gubernatura.csv</p>
                    <p>
                        ​Contiene los resultados de la votación por Acta a nivel Federal.
                    </p>
                    <p>
                        ​Muestra el número de votos recibidos por cada<br> uno de los Partidos Políticos, {{ _obtenerDatosService.config?.estado == 'Yucatán' ? 'Candidaturas Comunes' : 'Coaliciones' }},<br>  Candidaturas no<br> registradas y votos nulos.​
                    </p>
                </div>
                <div class="padding-b-40 mobile-parrafo">
                    <p class="font-bold">
                        &#8226; Gubernatura_candidatura_2022.csv
                    </p>
                    <p>
                        ​Describe las variables del archivo que contiene el<br> listado de candidaturas por Partidos Políticos <br> que participan en la elección<br> federal.​
                    </p>
                </div>
                <div class="padding-b-40 mobile-parrafo" id="descargar">
                    <p class="font-bold">
                        &#8226; LEEME.txt
                    </p>
                    <p>
                        ​Incluye la descripción y codificación de cada uno<br> de los campos que contiene la Base de datos. ​
                    </p>
                </div>
            </div>
            <div class="col-12 col-xl-7 p-l">
                <img style="width: 100%;" src="./assets/img/basededatos/carpetaarchivos.gif" alt="">
            </div>
        </div>
    </div>

    <!-- Descargar -->
    <div class="container-fluid py-5 descargar">
        <div class="row">
            <div class="col-12 p-l">
                <div class="subtitulo padding-b-40">
                    - Descargar -
                </div>
            </div>
            <div class="col-12 p-l-2">
                <div class="row">
                    <div class="col-12 col-lg-2">
                        <div class="item dis-flex align-s">
                            <div class="numero-descarga dis-flex justify-s padding-r-10 font-bold">
                                1
                            </div>
                            <div class="contenido dis-flex flex-col align-s">
                                <span class="font-bold">Descarga</span> Da clic en el botón<br> Base de datos​.
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-1 d-none d-lg-block">
                        <img class="img-descargar" src="./assets/img/basededatos/flecha-violeta.svg" alt="flecha">
                    </div>
                    <div class="col-12 col-lg-2">
                        <div class="item dis-flex align-s">
                            <div class="numero-descarga dis-flex justify-s padding-r-10 font-bold">
                                2
                            </div>
                            <div class="contenido dis-flex flex-col align-s">
                                <span class="font-bold">Localiza</span> El archivo en la<br> carpeta de Descargas.
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-1 d-none d-lg-block">
                        <img class="img-descargar" src="./assets/img/basededatos/flecha-violeta.svg" alt="flecha">
                    </div>
                    <div class="col-12 col-lg-2">
                        <div class="item dis-flex align-s">
                            <div class="numero-descarga dis-flex justify-s padding-r-10 font-bold">
                                3
                            </div>
                            <div class="contenido dis-flex flex-col align-s">
                                <span class="font-bold">Utiliza los comandos para localizar</span> Windows: CTRL + J<br> Mac: Alt + Cmd + L
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-1 d-none d-lg-block">
                        <img class="img-descargar" src="./assets/img/basededatos/flecha-violeta.svg" alt="flecha">
                    </div>
                    <div class="col-12 col-lg-2" id="descomprimir">
                        <div class="item dis-flex align-s">
                            <div class="numero-descarga dis-flex justify-s padding-r-10 font-bold">
                                4
                            </div>
                            <div class="contenido dis-flex flex-col align-s">
                                <span class="font-bold">Selecciona</span> Da clic sobre el archivo para seleccionarlo.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-l-2">
                <img class="img-descargar" src="./assets/img/basededatos/descargar.gif" alt="descargar">
            </div>
        </div>
    </div>


    <!-- Descomprimir -->
    <div class="container-fluid bgAzul txBlanco py-5 descomprimir">
        <div class="row">
            <div class="col-12 p-l">
                <div class="subtitulo padding-b-40">
                    - Descomprimir -
                </div>
            </div>
            <div class="col-12 padding-b-40 p-l-2">
                <div class="row">
                    <div class="col-12 col-lg-2 mobile-parrafo">
                        <span class="numero-desomprimir">1</span>. Sobre el archivo seleccionado, da clic derecho con el mouse e indica el tipo de extracción a realizar.​
                    </div>
                    <div class="col-12 col-lg-1 d-none d-lg-block">
                        <img class="img-descargar" src="./assets/img/basededatos/flecha-lila.svg" alt="flecha">
                    </div>
                    <div class="col-12 col-lg-2 mobile-parrafo">
                        Se abrirá una ventana mostrando el proceso de extracción. ​
                    </div>
                    <div class="col-12 col-lg-1 d-none d-lg-block">
                        <img class="img-descargar" src="./assets/img/basededatos/flecha-lila.svg" alt="flecha">
                    </div>
                    <div class="col-12 col-lg-2 mobile-parrafo">
                        <span class="numero-desomprimir">2</span>. Vuelve a dar clic derecho sobre el archivo zip que se extrajo y selecciona Extraer aquí​.
                    </div>
                    <div class="col-12 col-lg-1 d-none d-lg-block">
                        <img class="img-descargar" src="./assets/img/basededatos/flecha-lila.svg" alt="flecha">
                    </div>
                    <div class="col-12 col-lg-2 mobile-parrafo" id="abrir">
                        Se extraerá la carpeta contenida. ​​
                    </div>
                </div>
            </div>
            <div class="p-l-2">
                <img class="img-descomprimir" src="./assets/img/basededatos/descomprimir.gif" alt="descomprimir">
            </div>
            <div class="col-12 text-center mobile-parrafo">
                Software para descomprimir archivos zip:<br> ​Winrar o 7-Zip (Windows) / Zipeg, Expander o RAR (Mac)<br> Winzip, no funciona​
            </div>
        </div>
    </div>


    <!-- Abrir -->
    <div class="container-fluid py-5 abrir">
        <div class="row">
            <div class="col-12 p-l">
                <div class="subtitulo padding-b-40">
                    - Abrir -
                </div>
            </div>
            <div class="col-12 padding-b-60 p-l-2">
                <div class="row">
                    <div class="col-12 col-lg-3 mobile-parrafo">
                        <span class="font-bold">Da doble clic</span> sobre el archivo que quieras abrir.
                    </div>
                    <div class="col-12 col-lg-1 d-none d-lg-block">
                        <img class="img-descargar" src="./assets/img/basededatos/flecha-violeta.svg" alt="flecha">
                    </div>
                    <div class="col-12 col-lg-3 mobile-parrafo">
                        <span class="font-bold">Información de archivos “csv”</span> <br> ​Se puede ver en programas de hoja de cálculo como: Excel, Access, Calc Open Office, Smartsheet, entre otros.​​
                    </div>
                    <div class="col-12 col-lg-1 d-none d-lg-block">
                        <img class="img-descargar" src="./assets/img/basededatos/flecha-violeta.svg" alt="flecha">
                    </div>
                    <div class="col-12 col-lg-3 mobile-parrafo">
                        <span class="font-bold"> Archivo LEEME.TXT</span><br> ​Abre con el bloc de notas en Windows o Notas en Mac.​
                    </div>
                </div>
            </div>
            <div class="p-l-2">
                <div class="row">
                    <div class="col-12 ">
                        <img class="img-abrir" src="./assets/img/basededatos/abrir.gif" alt="abrir">

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Consulta -->
    <div class="container-fluid txBlanco py-5 consulta" id="formato-celdas">
        <div class="row">
            <div class="col-12">
                <div class="text-center titulo pading-b-10">
                    Consulta
                </div>
            </div>
            <div class="col-12 p-l">
                <div class="subtitulo padding-b-20">
                    - Formato de celdas -
                </div>
                <div class="padding-b-40 mobile-parrafo">
                    Para una lectura correcta de las fechas y horas de <br> corte, cambia el formato de celdas de las siguientes <br> columnas a dd/mm/aaaa hh:mm
                </div>
                <div class="mobile-parrafo">
                    <p>
                        1. Selecciona las columnas​
                    </p>
                    <p>
                        2. Da clic derecho en el mouse​
                    </p>
                    <p id="interpretacion-base-de-datos">
                        3. Selecciona “Formato de celdas”​
                    </p>
                    <p>
                        4. Elige el formato<br> “dd/mm/aaaa hh:mm” y <span class="font-bold">Acepta​</span>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- Interpretación -->
    <div class="container-fluid py-5 interpretacion">
        <div class="row">
            <div class="col-12 p-l">
                <div class="subtitulo padding-b-40 txRosa">
                    - Interpretación de Base de Datos -
                </div>
                <div class="padding-b-40 mobile-parrafo">
                    A continuación te presentamos cuáles son los elementos que conforman el archivo de la base de datos. ​
                </div>
                <div>
                    <div class="row">
                        <div class="col-12">
                            <div class="txRosa fila-titulo font-bold">Fila 1</div>
                            <div class="fila mobile-parrafo font-bold">Tipo de elección</div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-lg-7">
                                    <div class="txRosa fila-titulo font-bold">
                                        Fila 2
                                    </div>
                                    <div class="fila mobile-parrafo">
                                        <span class="font-bold">Fecha y hora del corte de información</span> con el formato <span class="font-bold">DD/MM/AAAA HH:MM.</span>
                                        <br> Se especifica la hora en formato de 24 h, así como el huso horario del Centro de México.
                                        <div class="px11 my-2 px-2 py-3 bordes-laterales">
                                            <div class="supIzq"></div>
                                            <div class="supDer"></div>
                                            <div class="infIzq"></div>
                                            <div class="infDer"></div>
                                            <p class="p-0 m-0">
                                                Si deseas una revisión detallada de las variables de la Base de Datos descarga el <span class="underline">Manual Interpretación de la Base de Datos PREP, junio 2021.</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-5">
                                    <img class="img-fila2" src="./assets/img/basededatos/fila2.svg" alt="fila2">
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="txRosa fila-titulo font-bold">Fila 3</div>
                            <div class="fila mobile-parrafo">
                                <strong>Variables del resumen de la base de datos:​</strong>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="txRosa fila-titulo font-bold">Fila 4</div>
                            <div class="fila mobile-parrafo">
                                <strong>Resultado de las variables del resumen de la base de datos</strong>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-lg-7">
                                    <img class="img-fila4" src="./assets/img/basededatos/fila4.svg" alt="fila4">
                                </div>
                                <div class="col-12 col-lg-1 d-none d-lg-block">
                                    <img class="img-descargar" src="./assets/img/basededatos/flecha-violeta.svg" alt="flecha">
                                </div>
                                <div class="col-12 col-lg-4 mobile-parrafo">
                                    <ul>
                                        <li>Actas Esperadas​</li>
                                        <li>Actas Registradas</li>
                                        <li>​Actas Fuera de catálogo​</li>
                                        <li>Actas Capturadas y porcentaje​</li>
                                        <li>Actas Contabilizadas y porcentaje​</li>
                                        <li>Porcentaje de Actas con inconsistencias​</li>
                                        <li>Actas no Contabilizadas​</li>
                                        <li>Lista Nominal de Actas Contabilizadas</li>
                                        <li *ngIf="!config?.hide_especiales">​Total de votos con Casillas Especiales</li>
                                        <li *ngIf="!config?.hide_especiales">​Total de votos sin Casillas Especiales​</li>
                                        <li>Porcentaje de Participación Ciudadana</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="txRosa fila-titulo font-bold ">Fila 6</div>
                            <div class="fila mobile-parrafo">
                                <strong>
                                    Indica las variables de los resultados de votación, las cuales se pueden agrupar en​:
                                </strong>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="row">
                                <div class="col-12 col-lg-7">
                                    <img class="img-fila6" src="./assets/img/basededatos/fila6.svg" alt="fila6">
                                </div>
                                <div class="col-12 col-lg-1 d-none d-lg-block">
                                    <img class="img-descargar" src="./assets/img/basededatos/flecha-violeta.svg" alt="flecha">
                                </div>
                                <div class="col-12 col-lg-4 mobile-parrafo">
                                    <ul>
                                        <li>Identificación de las Actas​</li>
                                        <li>Totales derivados de la votación</li>
                                        <li>​Resultados de la votación</li>
                                        <li>​Estatus, traslado y seguridad de las Actas</li>
                                        <li>​Procesamiento de las Actas </li>
                                        <li>​Proceso de digitalización</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="txRosa fila-titulo font-bold">Fila 7</div>
                            <div class="fila mobile-parrafo">
                                <strong>A partir de esta fila se indican los datos correspondientes a cada Acta.</strong>
                            </div>
                        </div>
                        <div class="col-12" id="autenticidad">
                            <div class="row">
                                <div class="col-12 col-lg-7">
                                    <img class="img-fila7" src="./assets/img/basededatos/fila7.svg" alt="fila7">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid bgRosa txBlanco py-5 autenticidad">
        <div class="row">
            <div class="col-12 p-l">
                <div class="subtitulo padding-b-20">
                    - Autenticidad -
                </div>
                <div class="padding-b-80 mobile-parrafo">
                    Para validar la autenticidad de la imagen del Acta de tu casilla, puedes utilizar un software lector de código único de imagen de la siguiente forma.​​
                </div>
            </div>
            <div class="col-12 p-l-2 padding-b-20">
                <div class="row">
                    <div class="col-12 col-lg-3 mobile-parrafo">
                        <p class="font-bold">
                            Código HASH ​
                        </p>
                        <p>
                            &#8226; Es una serie de caracteres únicos asociados al archivo de la imagen digitalizada del Acta de Escrutinio y Cómputo (AEC). ​
                        </p>
                    </div>
                    <div class="col-12 col-lg-1 d-none d-lg-block">
                        <img class="img-descargar" src="./assets/img/basededatos/flecha-blanca.svg" alt="flecha">
                    </div>
                    <div class="col-12 col-lg-3 mobile-parrafo">
                        <p class="font-bold">
                            Verifica​​
                        </p>
                        <p>
                            &#8226; Si fue manipulada la imagen del AEC con algún editor de imágenes.
                        </p>
                        <p>
                            ​&#8226; Intento de alteración del archivo.
                        </p>
                        <p>
                            &#8226;​ Legitimidad de las imágenes y de la información.​
                        </p>
                    </div>
                    <div class="col-12 col-lg-1 d-none d-lg-block">
                        <img class="img-descargar" src="./assets/img/basededatos/flecha-blanca.svg" alt="flecha">
                    </div>
                    <div class="col-12 col-lg-3 mobile-parrafo">
                        <p class="font-bold">
                            Valida
                        </p>
                        <p>
                            &#8226; Descarga cualquier lector de Código SHA* (HASH 256) utilizado por el PREP para validar que el archivo no fue alterado.
                        </p>
                    </div>
                </div>
            </div>
            <div class="offset-3 col-6 padding-b-20">
                <img class="img-descargar" src="./assets/img/basededatos/autenticidad-1.gif" alt="autenticidad-1">
            </div>
            <div class="col-12 p-l-2 padding-b-40">
                <div class="row">
                    <div class="col-12 col-lg-3 mobile-parrafo">
                        <p class="font-bold">
                            Ubica​
                        </p>
                        <p>
                            &#8226; En el PREP, el Acta de la casilla que quieres verificar y descárgala en tu computadora.​
                        </p>
                    </div>
                    <div class="col-12 col-lg-1 d-none d-lg-block">
                        <img class="img-descargar" src="./assets/img/basededatos/flecha-blanca.svg" alt="flecha">
                    </div>
                    <div class="col-12 col-lg-3 mobile-parrafo">
                        <p class="font-bold">
                            Genera el Código HASH​​​
                        </p>
                        <p>
                            &#8226; Abre el lector de Código HASH 256 y sube el archivo de la imagen del Acta.
                        </p>
                        <p>
                            ​&#8226; ​Sigue la secuencia: File-Select File.​
                        </p>
                        <p>
                            ​&#8226; ​Selecciona la opción SHA256. ​
                        </p>
                        <p>
                            &#8226;​ Elige el archivo y presiona abrir.​
                        </p>
                    </div>
                    <div class="col-12 col-lg-1 d-none d-lg-block">
                        <img class="img-descargar" src="./assets/img/basededatos/flecha-blanca.svg" alt="flecha">
                    </div>
                    <div class="col-12 col-lg-3 mobile-parrafo">
                        <p class="font-bold">
                            Copia y pega​
                        </p>
                        <p>
                            &#8226; El código contenido en el archivo de resultados de la Base de Datos del PREP, en la columna SHA.​
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 padding-b-40">
                <div class="row">
                    <div class="col-12 col-lg-6 text-center">
                        <img class="img-autenticidad-2" src="./assets/img/basededatos/autenticidad-2.svg" alt="autenticidad-2">
                    </div>
                    <div class="col-12 col-lg-6 text-center">
                        <img class="img-autenticidad-3" src="./assets/img/basededatos/autenticidad-3.svg" alt="autenticidad-3">
                    </div>
                </div>
            </div>
            <div class="col-12 padding-b-40">
                <div class="row">
                    <div class="offset-2 col-12 col-lg-3 mobile-parrafo">
                        <p class="font-bold">
                            Compara
                        </p>
                        <p>
                            &#8226; El Código de la imagen del Acta con el que te dio el lector de Código HASH 256 y comprueba su autenticidad.
                        </p>
                        <p>
                            ​Todos los caracteres deben coincidir, sin importar mayúsculas o minúsculas​​
                        </p>
                    </div>
                    <div class="col-12 col-lg-2 text-center d-none d-lg-block">
                        <img class="img-flecha-blanca-sm" src="./assets/img/basededatos/flecha-blanca.svg" alt="flecha">
                    </div>
                    <div class="col-12 col-lg-3 mobile-parrafo">
                        <p class="font-bold">
                            Identifica alteración
                        </p>
                        <p>
                            &#8226; A través del Código HASH podrás identificar fácilmente si la imagen del Acta fue manipulada con algún software de edición de imágenes.​
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 padding-b-150">
                <div class="row">
                    <div class="col-12 col-lg-6 text-center">
                        <img class="img-autenticidad-4" src="./assets/img/basededatos/autenticidad-4.svg" alt="autenticidad-4">
                    </div>
                    <div class="col-12 col-lg-6 text-center">
                        <img class="img-autenticidad-5" src="./assets/img/basededatos/autenticidad-5.svg" alt="autenticidad-5">
                    </div>
                </div>
            </div>
        </div>
    </div>




    <!--  <app-base-datos-definicion></app-base-datos-definicion>
    <app-base-datos-carpeta-archivos></app-base-datos-carpeta-archivos>
    <app-base-datos-consulta></app-base-datos-consulta> -->
</div>