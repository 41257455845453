<div id="ver-detalle" class="anchor"></div>
<div class="container-fluid bgAzul txBlanco p-4 p-lg-5" >
    <div class="row">
        <div class="col-12 col-lg-10 p-0 p-lg-5">
            <h2 class="font-medium pb-4">- Ver detalle -</h2>
            <div class="container">
                <div class="row">
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cómo encuentro los resultados de mi sección?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Ingresa tu Entidad, Sección y tu Clave de elector, que se encuentran en tu identificación oficial y realiza la consulta. En escritorio encuentras esta búsqueda en la parte superior de la derecha con el nombre de Conoce los resultados de tu sección. En
                        móvil, ingresa a Menú, selecciona la opción Mi Casilla y mete tus datos.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cuál es mi sección?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'La sección que te corresponde se encuentra especificada en el frente de tu credencial de elector, ejemplo: SECCIÓN 0610.' | highlightSearch: Search"></p>
                    </div>
                    <div class="pregunta">
                        <h3 [innerHtml]="'¿Cuál es mi clave de elector?' | highlightSearch: Search"></h3>
                        <p [innerHtml]="'Tu clave de elector está especificada en el frente de tu credencial de elector, ejemplo: CLAVE DE ELECTOR GMVLMR8007501M100.' | highlightSearch: Search"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>