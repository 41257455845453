<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 d-block d-xl-none" style="margin-bottom: 15px;">
                        <p class="px12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer" [ngClass]="{'brdr-selector': !themeService.isDarkTheme()}">
                                <div class="activo izq dis-flex flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Mapa-B.svg">
                                    <!-- <i class="ine ine-mapa" aria-hidden="true"></i> -->
                                    <p>Mapa</p>
                                </div>
                                <div [routerLink]="['tarjetas']" class="dis-flex der flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-B.svg" style="opacity: 0.5;">
                                    <!-- <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-10">
                        <app-breadcrumb
                            subtitle="Entidad"
                            subtitle_detalle="Votos por Distritos"
                            estado="Nacional"
                        ></app-breadcrumb>
                        <div class="texto font-regular">
                            <p>
                                El total de votos calculado y porcentaje que se muestran, se refiere a los votos asentados en las Actas PREP hasta el momento.
                            </p>
                            <p>
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.
                            </p>
                            <p class="my-3">
                                En la tabla se muestra el número de Distritos que cada <strong>Partido Político, {{ _obtenerDatosService.config?.estado != 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }}</strong> ha obtenido hasta el momento.
                            </p>
                        </div>
                    </div>
                    <div class="col-2 d-none d-xl-block">
                        <p class="px12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer">
                                <div class="activo izq dis-flex flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Mapa-B.svg">
                                    <!-- <i class="ine ine-mapa" aria-hidden="true"></i> -->
                                    <p>Mapa</p>
                                </div>
                                <div [routerLink]="['tarjetas']" class="dis-flex der flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-B.svg" style="opacity: 0.5;">
                                    <!-- <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="_obtenerDatosService.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.votosDistritos?.resumenVotacion?.totalVotos?.total"
                    [total_prc]="_obtenerDatosService.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.votosDistritos?.resumenVotacion?.totalVotos?.porcentaje"
                ></app-resumen-votacion-mobile>
                <div class="row py-4">
                    <div class="col-12">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <div class="contenedor-mapa">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="tabla container-fluid">
                                <div class="row titulos">
                                    <div class="col-6 px-0">
                                        <h4 class="subtitulo2-seccion mb-0 h-100 titulo-distritos">
                                            <strong>Distritos obtenidos</strong>
                                        </h4>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-5 pr-0">
                                                <h4 class="subtitulo2-seccion">
                                                    <strong>Votos</strong>
                                                </h4>
                                            </div>
                                            <div class="col-7">
                                                <h4 class="subtitulo2-seccion titulo-porcentaje font-medium">
                                                    <strong>Porcentaje de votos</strong>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row fila col-12 px-0 mx-0" *ngFor="let fila of _obtenerDatosService.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.votosDistritos?.votos" [ngClass]="{filaGanador: fila?.ganador}">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-8 dis-flex justify-s pl-0">
                                                <div *ngFor="let partido of fila.partidos; let i = index">
                                                    <div class="color-partido mx-1" *ngIf="i === 0" [ngStyle]="{'background': _obtenerDatosService?.diccionarioPartidos[partido].colorPartido}">
                                                        &nbsp;
                                                    </div>
                                                </div>
                                                <p class="pe" *ngFor="let partido of fila.partidos" [ngStyle]="{
                                                   'background-image': 'url(./assets/img/logos-partidos/'+  _obtenerDatosService?.diccionarioPartidos[partido]?.imgPartido?.toLowerCase() +')',
                                                   'background-size': 'contain',
                                                   'background-repeat': 'no-repeat',
                                                   'background-position': 'center'
                                               }">
                                                    <!-- <strong>{{ partido }}</strong> -->
                                                </p>
                                            </div>
                                            <div class="col-4 distrito-numero dis-flex justify-e">
                                                <p>
                                                    <strong>{{ fila?.distritos }}</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 dis-flex">
                                        <div class="container-fluid px-0">
                                            <div class="row">
                                                <div class="col-6 votos-numero dis-flex justify-e">
                                                    <p>
                                                        <strong>{{ fila?.votos }}</strong>
                                                    </p>
                                                </div>
                                                <div class="col-6 votos-porcentaje dis-flex flex-col align-s px-0">
                                                    <p>
                                                        <strong>{{ fila?.porcentaje }}%</strong>
                                                    </p>
                                                    <div class="mini-grafica w-100">
                                                        <div [ngStyle]="{'background': _obtenerDatosService?.diccionarioPartidos[fila?.partidos[0]].colorPartido}" [style.width.%]="fila?.porcentaje" class="avance">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-12 py-4">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-8 dis-flex justify-s pl-0">
                                                <p>
                                                    <strong>Total</strong>
                                                </p>
                                            </div>
                                            <div class="col-4 distrito-numero dis-flex justify-e no-border">
                                                <p>
                                                    <strong>{{_obtenerDatosService.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.votosDistritos?.votosTotal}}</strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 d-none d-md-block">
                            <div>
                                <span class="px18 txRosaIne">
                                    Mapa Electoral
                                </span>
                                <p class="px15 padding-b-10">
                                    Resalta el Partido Político, {{ _obtenerDatosService.config?.estado != 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }} que aventaja hasta el momento en el Distrito.
                                </p>
                                <p class="px14">
                                    Para conocer el detalle del Distrito, pasa el cursor o da clic sobre éste.
                                </p>
                            </div>
                            <!--  <div class="h-100 dis-flex"> -->
                            <div class="h-100">
                                <div [chart]="map"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="container-fluid py-4">
                     <div class="row">
                         <div class="col-md-12 dis-flex justify-e">
                             <i class="fas fa-info-circle mr-2" aria-hidden="true"></i>
                             <p class="texto">
                                 Da clic en <strong>Seleccionar</strong> para visualizar el detalle de la votación. Puedes seleccionar hasta tres opciones.
                             </p>
                             <button class="btn btn-outline-secondary btn-sm texto ml-2">
                                 Seleccionar
                             </button>
                         </div>
                     </div>
                 </div> -->

                <div class="container-fluid">
                    <div class="row pb-4">
                        <div class="col-10">
                            <p class="font-medium px14">
                                No se considera la cantidad de votos asentada en las Actas PREP de Casilla Especial de Representación Proporcional.
                            </p>
                        </div>
                    </div>
                </div>

                <app-resumen-votacion
                    [acumulados_num]="_obtenerDatosService.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.votosDistritos?.resumenVotacion?.acumulados?.total"
                    [acumulados_prc]="_obtenerDatosService.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.votosDistritos?.resumenVotacion?.acumulados?.porcentaje"
                    [noregistradas_num]="_obtenerDatosService.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.votosDistritos?.resumenVotacion?.noRegistradas?.total"
                    [noregistradas_prc]="_obtenerDatosService.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.votosDistritos?.resumenVotacion?.noRegistradas?.porcentaje"
                    [nulos_num]="_obtenerDatosService.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.votosDistritos?.resumenVotacion?.nulos?.total"
                    [nulos_prc]="_obtenerDatosService.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.votosDistritos?.resumenVotacion?.nulos?.porcentaje"
                    [total_num]="_obtenerDatosService.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.votosDistritos?.resumenVotacion?.totalVotos?.total"
                    [total_prc]="_obtenerDatosService.dataDiputacionesEntidad['entidad'+ _globalService.rutaParametros.noEntidad]?.votosDistritos?.resumenVotacion?.totalVotos?.porcentaje"
                ></app-resumen-votacion>

            </div>
        </div>
    </div>
</div>

<app-estadistica-nacional></app-estadistica-nacional>
