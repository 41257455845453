<div class="seccion-resumen container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-lg-10">
                        <app-breadcrumb
                            subtitle="Entidad"
                            subtitle_detalle="Votos por Partido Político y Candidatura Independiente"
                            estado="Nacional"
                        ></app-breadcrumb>
                        <div class="texto font-regular">
                            <p>
                                El total de votos calculado y porcentaje que se muestran, se refiere a los votos asentados en las Actas PREP hasta el momento.
                            </p>
                            <p>
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row pb-4">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <div class="acta-digitalizada container-fluid pb-3">
                    <div class="row">
                        <div class="col-7">
                            <p class="pb-3">Acta digitalizada</p>
                            <button class="btn boton-rosa txBlanco px-5">
                                <i class="far fa-file" aria-hidden="true"></i>
                                <i class="fas fa-print" aria-hidden="true"></i>
                                Ver
                            </button>
                        </div>
                        <div class="col-5">
                            <p class="font-medium pb-3">
                                Imagen procedente de:
                            </p>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-5 dis-flex">
                                        <div class="grupo gris dis-flex">
                                            <i class="fas fa-file"></i>
                                            <i class="fas fa-hourglass"></i>
                                            <p>
                                                Acta en proceso
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-4 dis-flex">
                                        <div class="grupo dis-flex cursor-hand" tooltip="Acta PREP digitalizada por medio de escáner." placement="top" max-width="500">
                                            <i class="far fa-file"></i>
                                            <i class="fas fa-print" aria-hidden="true"></i>
                                            <p>
                                                Escáner
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-3 dis-flex">
                                        <div class="grupo dis-flex cursor-hand" tooltip="Acta PREP digitalizada por medio de dispositivo móvil." placement="top" max-width="500">
                                            <i class="far fa-file"></i>
                                            <i class="fas fa-mobile-alt"></i>
                                            <p>
                                                Móvil
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               <div class="container-fluid">
                    <div class="row dis-flex pb-3">
                        <div class="col-md-12">
                            <div class="row dis-flex justify-s">
                                <div *ngFor="let dato of contenido" 
                                    class="col-6 col-sm-4 col-md-3 col-lg-2  card-resumen px-0">
                                    <div class="encabezado dis-flex w-100 py-2">
                                        <div class="dis-flex w-50">
                                            <p class="pe">{{ dato.pe }}</p>
                                        </div>
                                        <!-- <div class="separador"></div>
                                        <div class="distritos dis-flex w-50">
                                            <div class="dis-flex flex-col">
                                                <p class="titulos">
                                                    Distritos
                                                </p>
                                                <p class="distrito font-medium w-100">
                                                    <strong>{{ dato?.distritos }}</strong>
                                                </p>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="cuerpo dis-flex bgGrisTenue p-2">
                                        <div class="w-50">
                                            <p>Votos</p>
                                            <p class="votos">
                                                <strong>{{ dato?.votos }}</strong>
                                            </p>
                                        </div>
                                        <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                            <p>Porcentaje</p>
                                            <p>{{ dato?.porcentaje }}</p>
                                        </div>
                                    </div>
                                    <div class="pie dis-flex bgMorado txBlanco p-2">
                                        <p>Seleccionar</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
                <div class="container-fluid resumen-votacion">
                    <div class="row">
                        <div class="col-12 col-lg-8 col-xl-8 bgGris py-2 pxx">
                            <p class="titulo">
                                <strong>Resumen de la votación</strong>
                            </p>
                            <div class="container-fluid">
                                <div class="row dis-flex">
                                    <div class="apartado dis-flex flex-col">
                                        <div class="dis-flex w-100 justify-s">
                                            <p class="subtitulo">
                                                Votos acumulados
                                            </p>
                                        </div>
                                        <div class="row dis-flex w-100 space-b">
                                            <p class="col-12 cantidad">
                                                <strong>41,748,301</strong>
                                            </p>
                                            <p class="col-12 porcentaje font-medium">
                                                95.1114%
                                            </p>
                                        </div>
                                    </div>
                                    <p class="separador pt-5 pr-3">
                                        <strong>+</strong>
                                    </p>
                                    <div class="apartado dis-flex flex-col">
                                        <div class="dis-flex w-100 justify-s">
                                            <p class="subtitulo">
                                                Candidaturas no registradas
                                            </p>
                                        </div>
                                        <div class="row dis-flex w-100 space-b">
                                            <p class="col-12 cantidad">
                                                <strong>48,514</strong>
                                            </p>
                                            <p class="col-12 porcentaje font-medium">
                                                00.1114%
                                            </p>
                                        </div>
                                    </div>
                                    <p class="separador pt-5 pr-3">
                                        <strong>+</strong>
                                    </p>
                                    <div class="apartado dis-flex flex-col">
                                        <div class="dis-flex w-100 justify-s">
                                            <p class="subtitulo">
                                                Nulos
                                            </p>
                                        </div>
                                        <div class="row dis-flex w-100 space-b">
                                            <p class="col-12 cantidad">
                                                <strong>1,724,787</strong>
                                            </p>
                                            <p class="col-12 porcentaje font-medium">
                                                03.9630%
                                            </p>
                                        </div>
                                    </div>
                                    <p class="d-none d-lg-block separador pr-3">
                                        <strong>=</strong>
                                    </p>
                                    <div class="d-none d-lg-block apartado dis-flex flex-col">
                                        <div class="dis-flex w-100 justify-e">
                                            <p class="px20">
                                                <strong>Total de votos</strong>
                                            </p>
                                        </div>
                                        <div class="dis-flex w-100 space-b">
                                            <p class="total">
                                                <strong class="txRosaIne">43,521,605</strong>
                                            </p>
                                        </div>
                                        <div class="dis-flex w-100 justify-e">
                                            <p>
                                                <strong>
                                                    <span class="txRosaIne">100.0000%</span>
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="container-fluid d-lg-none">
                                    <div class="row">
                                        <div class="col-12 dis-flex flex-col mt-3">
                                            <div class="dis-flex w-100 justify-e">
                                                <p class="px20 mb-0">
                                                    <strong>Total de votos</strong>
                                                </p>
                                            </div>
                                            <div class="dis-flex w-100 justify-e">
                                                <p class="total">
                                                    <strong>
                                                        = <span class="txRosaIne">43,521,605</span>
                                                    </strong>
                                                </p>
                                            </div>
                                            <div class="dis-flex w-100 justify-e">
                                                <p>
                                                    <strong>
                                                        <span class="txRosaIne">100.0000%</span>
                                                    </strong>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row tabla-seccion">
        <div class="col-12">
            <div class="tabla card pb-3">
                <div class="contenido">
                    <div class="container-fluid">
<!-- Títulos -->
                        <div class="row titulos text-center">
                            <div class="col-3 col-sm-2 dis-flex">
                                <p>
                                    <strong>
                                        Casilla
                                    </strong>
                                </p>
                            </div>
                            <div class="col-4 col-sm-2 dis-flex">
                                <p>
                                    <strong>
                                        Acta <br>digitalizada
                                    </strong>
                                </p>
                            </div>
                            <div class="col-3 col-sm-2 dis-flex">
                                <p class="pe">
                                    P5
                                </p>
                                <p class="pe">
                                    P8
                                </p>
                                <p class="pe">
                                    P9
                                </p>
                            </div>
                            <div class="col-3 col-sm-1 dis-flex">
                                <p class="pe">
                                    P5
                                </p>
                                <p class="pe">
                                    P8
                                </p>
                            </div>
                            <div class="col-3 col-sm-1 dis-flex">
                                <p class="pe">
                                    P5
                                </p>
                                <p class="pe">
                                    P9
                                </p>
                            </div>
                            <div class="col-3 col-sm-1 dis-flex">
                                <p class="pe">
                                    P8
                                </p>
                                <p class="pe">
                                    P9
                                </p>
                            </div>
                            <div class="col-4 col-sm-2 dis-flex txGris">
                                <p>
                                    <strong>
                                        Candidaturas no registradas
                                    </strong>
                                </p>
                            </div>
                            <div class="col-3 col-sm-1 dis-flex txGris">
                                <p>
                                    <strong>
                                        Votos nulos
                                    </strong>
                                </p>
                            </div>
                            <div class="col-4 col-sm-2 dis-flex txGris">
                                <p>
                                    <strong>
                                        Total
                                    </strong>
                                </p>
                            </div>
                            <div class="col-5 col-sm-2 txGris">
                                <p>
                                    <strong>
                                        Observaciones<br>en Acta
                                    </strong>
                                </p>
                            </div>
                        </div>
<!-- Filas -->
                        <div class="row filas text-center" *ngFor="let casilla of casillas">
                            <div class="col-3 col-sm-2 dis-flex justify-s">
                                <p>
                                    {{ casilla?.casilla }}
                                </p>
                            </div>
                            <div class="col-4 col-sm-2 dis-flex">
                                <i class="far fa-file" aria-hidden="true"></i>
                                <i class="fas fa-print" aria-hidden="true"></i>
                            </div>
                            <div class="col-3 col-sm-2 dis-flex">
                                <p>
                                    {{ casilla?.coalicion1 }}
                                </p>
                            </div>
                            <div class="col-3 col-sm-1 dis-flex">
                                <p>
                                    {{ casilla?.coalicion2 }}
                                </p>
                            </div>
                            <div class="col-3 col-sm-1 dis-flex">
                                <p>
                                    {{ casilla?.coalicion3 }}
                                </p>
                            </div>
                            <div class="col-3 col-sm-1 dis-flex">
                                <p>
                                    {{ casilla?.coalicion4 }}
                                </p>
                            </div>
                            <div class="col-4 col-sm-2 dis-flex">
                                <p>
                                    {{ casilla?.candidaturasNoRegistradas }}
                                </p>
                            </div>
                            <div class="col-3 col-sm-1 dis-flex">
                                <p>
                                    {{ casilla?.votosNulos }}
                                </p>
                            </div>
                            <div class="col-4 col-sm-2 dis-flex ">
                                <p>
                                    {{ casilla?.total }}
                                </p>
                            </div>
                            <div class="col-5 col-sm-2 dis-flex">
                                <p>
                                    {{ casilla?.observaciones }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="estadistica-casilla card mt-3 p-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <h4 class="subtitulo-seccion pleca-izquierda">
                                <strong>Estadística de Casilla</strong>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col">
                            <p class="subtitulo">
                                <strong>Participación ciudadana conforme a las Actas Contabilizadas</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-10">
                            <div class="grafica p-2">
                                <div class="textos">
                                    <p class="casilla">
                                        821 Casilla Básica
                                    </p>
                                    <p class="porcentaje">
                                        <strong>
                                            68.1739%
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 dis-flex flex-col align-e pr-5">
                            <p class="lista-nominal">
                                <strong>
                                    Total Lista nominal
                                </strong>
                            </p>
                            <p class="total-nominal">
                                <strong>
                                    575
                                </strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="estadistica-casilla observacionesActa card mt-3 p-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <h4 class="subtitulo-seccion pleca-izquierda">
                                <strong>Observaciones en Acta</strong>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="row justify-content-center">
                        <div class="col-11">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <h4 class="subtitulo-seccion pleca-izquierda">
                                            <strong class="txRosa2">Contabilizadas</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Ilegible
                                                </strong>
                                            </p>
                                            <p>
                                                La cantidad de votos en algún campo del Acta PREP no puede leerse, tanto en letra como en número.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Sin Dato
                                                </strong>
                                            </p>
                                            <p>
                                                La cantidad de votos no fue asentada en el Acta PREP ni en letra ni en número en algún campo del Acta PREP.
                                            </p>
                                        </div>
                                        <!-- <div class="grupo py-3 mb-2">
                                            <p class="pb-2">
                                                <strong>
                                                    Identificación por Proceso Técnico Operativo
                                                </strong>
                                            </p>
                                            <p>
                                                Los datos del Acta PREP no se identificaron y sé solicitó verificarlos por algún procedimiento válido establecido en el Proceso Técnico Operativo.
                                            </p>
                                        </div> -->
                                        <h4 class="subtitulo-seccion pleca-izquierda mt-5">
                                            <strong>No identificadas - Fuera del catálogo</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p>
                                                No es posible ubicar el Acta PREP dentro de la lista de Actas de casillas aprobadas por el INE, al presentar alguna omisión, ilegibilidad o error en sus datos de identificación. Para fines de publicación, el Acta se muestra al final del listado de Actas aprobadas en la Base de Datos.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <h4 class="subtitulo-seccion pleca-izquierda">
                                            <strong>No Contabilizadas</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Excede Lista Nominal
                                                </strong>
                                            </p>
                                            <p>
                                                La suma de todos los votos en el Acta PREP, excede el número de ciudadanas y ciudadanos en la Lista Nominal de Electores de la casilla, incluido el número de votos emitidos por los representantes de los Partidos Políticos.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Sin Acta
                                                </strong>
                                            </p>
                                            <p class="pb-2">
                                                El Acta PREP no llegó junto con el paquete electoral al Centro de Acopio y Transmisión de Datos (CATD), por algunas de las siguientes causas:
                                            </p>
                                            <p>
                                                1. Sin Acta por paquete no entregado.
                                            </p>
                                            <p>
                                                2. Sin Acta por casilla no instalada.
                                            </p>
                                            <p>
                                                3. Sin Acta por paquete entregado sin bolsa.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Todos los campos ilegibles
                                                </strong>
                                            </p>
                                            <p>
                                                Todos los campos del Acta PREP correspondientes al número de votos, son ilegibles tanto en letra como en número.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Todos los campos vacíos
                                                </strong>
                                            </p>
                                            <p>
                                                Todos los campos del Acta PREP correspondientes al número de votos, se encuentran vacíos tanto en letra como en número.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>