<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-lg-10">
                        <app-breadcrumb
                            subtitle="Detalle del Distrito"
                            subtitle_detalle="Votos por Partido Político y Candidatura Independiente"
                            [estado]="_obtenerDatosService?.config?.estado"
                            [distrito]="rutas.migas?.distrito"
                            [distrito_param]="rutas.migas?.distrito_clave"
                        ></app-breadcrumb>
                        <div class="texto font-regular">
                            <p>El total de votos calculado y porcentaje que se muestran, se refiere a los votos asentados en las Actas PREP hasta el momento.</p>
                            <br>
                            <p>Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.</p>
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="resumen?.total?.total"
                                             [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion-mobile>
                <div class="row pb-3 mt-2">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>

                <!-- INICIA CARRUSEL DE TARJETAS -->
                <div class="container-fluid d-none d-md-none d-xl-block">
                    <div class="row dis-flex pb-1">
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollLeft()">
                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                        </div>
                        <div class="col-11">
                            <div #widgetsContent class="row dis-flex justify-s scrollH">
                                <div *ngFor="let tarjeta of fichas; let indx = index;" class="col-6 col-sm-4 col-md-3 col-lg-2 px-1">
<!--                      <div *ngIf="indx<(resumen_table.length - 3)" class="card-resumen">-->
                                    <div class="card-resumen">
                          <!-- <div class="card-resumen"> -->
                                        <div class="encabezado dis-flex w-100 py-2">
                                            <div class="dis-flex w-100">
                                                <div class="row w-100 dis-flex">
                                                    <ng-container *ngFor="let img of tarjeta?.imgs">
                                                        <img style="height: 30px; border-radius: 0%; width: auto;" [src]="'./assets/img/logos-partidos/'+img?.toLowerCase()" />
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cuerpo dis-flex bgGrisTenue p-2">
                                            <div class="w-50">
                                                <p>Votos</p>
                                                <p class="votos">
                                                    <strong>{{ tarjeta?.total }}</strong>
                                                </p>
                                            </div>
                                            <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                                <p>Porcentaje</p>
                                                <p>{{ tarjeta?.porcentaje }}</p>
                                            </div>
                                        </div>
                                        <div *ngIf="!tarjeta.seleccionado" (click)="agregarElemento(tarjeta);" class="pie dis-flex bgMorado txBlanco p-2 cursorPointer">
                                            <p>Seleccionar</p>
                                        </div>
                                        <div *ngIf="tarjeta.seleccionado" (click)="eliminarElemento(tarjeta);" class="pie dis-flex bgMorado txBlanco p-2 cursorPointer seleccionado">
                                            <p>Quitar selección</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollRight()">
                            <i class="fas fa-chevron-right" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <!-- TERMINA CARRUSEL DE TARJETAS -->
                <!-- TARJETAS MOVIL -->
                <movil-listado-content 
                    [selectedItemFunc]="selectedItemFun" 
                    [diselectedItemFunc]="diselectedItemFun"
                    [infoList]="fichas" 
                    [tipoTarjeta]="3"></movil-listado-content>
                <!--********************************************************************************************************************-->
          
                <div class="container-fluid">
                    <div class="row dis-flex">
                        <div class="col-md-12">
                            <div class="row dis-flex justify-s"></div>
                        </div>
                    </div>
                </div>
                <!-- <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col-md-12 dis-flex justify-e">
                            <i class="fas fa-info-circle mr-2" aria-hidden="true"></i>
                            <p class="texto">Da clic en <strong>Seleccionar</strong> para visualizar el detalle de la votación. Puedes seleccionar hasta tres opciones.</p>
                            <button [disabled]="comparativaService?.arregloElementos.length < 1"
                                    (click)="comparativaService.reiniciarModal()"
                                    class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer" data-toggle="modal"
                                    data-target="#modalComparativa"> Ver detalle</button>
                        </div>
                    </div>
                </div> -->
                <p class="p-3" style="font-size: 1.4em; text-align: justify;">Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.</p>

                <app-resumen-votacion
                      extranjero="false"      
                      [acumulados_num]="resumen?.acumulados?.total"
                      [acumulados_prc]="resumen?.acumulados?.prc"
                      [noregistradas_num]="resumen?.no_registradas?.total"
                      [noregistradas_prc]="resumen?.no_registradas?.prc"
                      [nulos_num]="resumen?.nulos?.total"
                      [nulos_prc]="resumen?.nulos?.prc"
                      [total_num]="resumen?.total?.total"
                      [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion>
            </div>
        </div>
    </div>
</div>

<app-distribucion-votos2></app-distribucion-votos2>
<app-estadistica-nacional
  [tipo_estadistica]="rutas.migas?.distrito"
  [short_tpl]="true"
  [short_data]="short_data"
></app-estadistica-nacional>
