<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 d-block d-xl-none" style="margin-bottom: 15px;">
                        <p class="px12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer">
                                <div [routerLink]="['../mapa']" class="izq dis-flex flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Mapa-A.svg">
                                    <!-- <i class="ine ine-mapa" aria-hidden="true"></i> -->
                                    <p>Mapa</p>
                                </div>
                                <div class="activo dis-flex der flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-A.svg" style="opacity: 0.5;">
                                    <!-- <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-10">
                        <app-breadcrumb
                            subtitle="Circunscripción"
                            subtitle_detalle="Votos por Distritos"
                            estado="Nacional"
                        ></app-breadcrumb>
                        <div class="texto font-regular">
                            <p>
                                El total de votos calculado y porcentaje que se muestran, se refiere a los votos asentados en las Actas PREP hasta el momento.
                            </p>
                            <p>
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.
                            </p>
                            <p class="my-3">
                                En las tarjetas se muestra el número de Distritos que cada <strong>Partido Político, {{ _obtenerDatosService.config?.estado != 'Oaxaca' ? 'Candidatura Común' : 'Coalición' }}</strong> ha obtenido hasta el momento.
                            </p>
                        </div>
                    </div>
                    <app-resumen-votacion-mobile
                        [total_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosDistritos?.resumenVotacion?.totalVotos?.total"
                        [total_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosDistritos?.resumenVotacion?.totalVotos?.total"
                    ></app-resumen-votacion-mobile>
                    <div class="col-2 d-none d-xl-block">
                        <p class="px12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer">
                                <div [routerLink]="['../mapa']"
                                    class="izq dis-flex flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Mapa-A.svg" style="opacity: 0.5;">
                                    <!-- <i class="ine ine-mapa" aria-hidden="true"></i> -->
                                    <p>Mapa</p>
                                </div>
                                <div
                                    class="activo dis-flex der flex-col w-50 p-2">
                                    <img class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-A.svg">
                                    <!-- <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pb-3 mt-2">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
               <div class="container-fluid">
                    <div class="row dis-flex pb-1">
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollLeft()">
                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                        </div>
                        <div class="col-11">
                            <div #widgetsContent class="row dis-flex justify-s scrollH">
                                <div *ngFor="let tarjeta of _obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosDistritos?.votos"
                                    class="col-6 col-sm-4 col-md-3 col-lg-2 px-1">
                                    <!-- <div class="card-resumen" [ngClass]="{cardGanador: tarjeta?.ganador}"> -->
                                    <div class="card-resumen">
                                        <div class="encabezado dis-flex w-100 py-2">
                                            <div class="dis-flex w-50">
                                                <div class="row w-80 dis-flex"
                                                    [ngClass]="{'justify-s': tarjeta?.partidos?.length > 1}">
                                                    <p class="pe p-0"
                                                        *ngFor="let partido of tarjeta.partidos"
                                                        [ngClass]="{'col-6 pePequeno': tarjeta?.partidos?.length > 1}"
                                                        [ngStyle]="{
                                                            'background-image': 'url(./assets/img/logos-partidos/'+  _obtenerDatosService?.diccionarioPartidos[partido]?.imgPartido?.toLowerCase() +')',
                                                            'background-size': 'contain',
                                                            'background-repeat': 'no-repeat',
                                                            'background-position': 'center'
                                                        }">
                                                        <!-- {{ partido }} -->
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="separador"></div>
                                            <div class="distritos dis-flex w-50">
                                                <div class="dis-flex flex-col">
                                                    <p class="titulos">
                                                        Distritos
                                                    </p>
                                                    <p class="distrito font-medium w-100">
                                                        <strong>{{ tarjeta?.distritos }}</strong>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cuerpo dis-flex bgGrisTenue p-2">
                                            <div class="w-50">
                                                <p>Votos</p>
                                                <p class="votos">
                                                    <strong>{{ tarjeta?.votos }}</strong>
                                                </p>
                                            </div>
                                            <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                                <p>Porcentaje</p>
                                                <p>{{ tarjeta?.porcentaje }}%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollRight()">
                            <i class="fas fa-chevron-right" aria-hidden="true"></i>
                        </div>
                    </div>
               </div>
                <div class="container-fluid">
                    <div class="row dis-flex">
                        <div class="col-md-12">
                            <div class="row dis-flex justify-s">

                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="container-fluid py-1">
                    <div class="row">
                        <div class="col-md-12 dis-flex flex-col align-e">
                            <div class="dis-flex">
                                <i class="fas fa-info-circle mr-2" aria-hidden="true"></i>
                                <p class="texto">
                                    Da clic en <strong>Seleccionar</strong> para visualizar el detalle de la votación. Puedes seleccionar hasta tres opciones.
                                </p>
                            </div>
                            <button class="btn btn-outline-secondary btn-sm texto ml-2 px-4">
                                Ver detalle
                            </button>
                        </div>
                    </div>
                </div> -->
                <app-resumen-votacion
                    [acumulados_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosDistritos?.resumenVotacion?.acumulados?.total"
                    [acumulados_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosDistritos?.resumenVotacion?.acumulados?.porcentaje"
                    [noregistradas_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosDistritos?.resumenVotacion?.noRegistradas?.total"
                    [noregistradas_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosDistritos?.resumenVotacion?.noRegistradas?.porcentaje"
                    [nulos_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosDistritos?.resumenVotacion?.nulos?.total"
                    [nulos_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosDistritos?.resumenVotacion?.nulos?.porcentaje"
                    [total_num]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosDistritos?.resumenVotacion?.totalVotos?.total"
                    [total_prc]="_obtenerDatosService.dataDiputacionesCircunscripcion[_globalService.rutaParametros.circunscripcion]?.votosDistritos?.resumenVotacion?.totalVotos?.total"
                ></app-resumen-votacion>

            </div>
        </div>
    </div>
</div>
<app-estadistica-nacional></app-estadistica-nacional>
