<div class="row tabla-seccion tabla-scroll mt-3 d-none d-xl-block">
    <div class="col-12">
        <div class="tabla card pb-3">
            <div class="p-3 pb-0">
                <p class="titulo text-left">
                    <strong class="underline">
                        Participación ciudadana con base en la Lista Nominal de las Actas PREP Contabilizadas
                    </strong>
                </p>
            </div>
            <div class="contenido max-500">
                <div class="container-fluid">
<!-- Títulos -->
                    <div class="row titulos text-center sticky-header">
                        <div class="col-4 dis-flex py-2">
                            <p>
                                <strong>
                                    Casilla
                                </strong>
                            </p>
                        </div>
                        <div class="col-4 dis-flex">
                            <p>
                                <strong>
                                    Participación ciudadana
                                </strong>
                            </p>
                        </div>
                        <div class="col-4 dis-flex">
                            <p>
                                <strong>
                                    Total de la Lista Nominal
                                </strong>
                            </p>
                        </div>
                    </div>
<!-- Filas -->
                    <div class="row filas text-center" *ngFor="let casilla of _obtenerDatosService.participacion_table">
                        <div class="col-4 dis-flex">
                            <a *ngIf="casilla?.c1?.param" href="./{{contiendaActual}}/{{param}}/secciones/{{casilla?.c1?.seccion}}/casilla/{{casilla?.c1?.param}}"><b>{{casilla?.c1?.numero}} {{casilla?.c1?.text}}</b></a>
                            <a *ngIf="!casilla?.c1?.param"><b>{{casilla?.c1?.numero}} {{casilla?.c1?.text}}</b></a>
                        </div>
                        <div class="col-4 dis-flex">
                            <p>
                              {{ casilla?.c2 }}
                            </p>
                        </div>
                        <div class="col-4 dis-flex">
                            <p>
                              {{ casilla?.c3 }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
