import { Component, OnInit, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data, RoutesRecognized, ParamMap, Route, ActivatedRouteSnapshot, NavigationStart } from '@angular/router';
import { Observable } from 'rxjs';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { ObtenerDatosService } from '../servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from '../servicios/globales.service';
import { RutasService } from '../servicios/rutas/rutas.service';
import { ThemeService } from '../theme/theme.service';
import { GoogleAnalyticsService } from '../servicios/googleanalytics/googleanalytics.service';

// declare let ga: Function;

// declare var $;

@Component({
  selector: 'app-contiendas',
  templateUrl: './contiendas.component.html',
  styleUrls: ['./contiendas.component.scss']
})
export class ContiendasComponent implements OnInit {
  title = 'publicacion';
  hideAvance = false;
  ga_created = false;
  public config:any = {};

    constructor(
        public globalService: GlobalesService,
        private readonly router: Router,
        private readonly rutas: RutasService,
        private readonly _obtenerDatosService: ObtenerDatosService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly titleService: Title,
        public themeService: ThemeService,
        private _analytics: GoogleAnalyticsService

        // private paramMap: ParamMap
    ) {

      this.router.routeReuseStrategy.shouldReuseRoute = function() {
        return false;
      };
        // const data: Data = this.route;

        if (this.globalService.navigationSubscribed == false) {
          this.globalService.navigationSubscribed = true;
          this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                this.setStateParams(e.urlAfterRedirects);
                this.obtenerRutaActual(e.urlAfterRedirects);
                this.parametrosUrl();
                this.rutas.obtenerDominio();

                let arrRuta = this.globalService.rutaActual.split("/");

                if (globalService.arrRutasSinAvance.indexOf(arrRuta[1]) >= 0) this.globalService.hideAvance = true;
                else {
                  this.globalService.menuActual = 'Votos';
                  this.globalService.hideAvance = false;
                }

        				this.globalService.inicializarContienda(this.rutas.rutaActual.ruta);
        				this.globalService.scrollToTop();

                this._analytics.trackSinglePageView(e);
              }
              if (e instanceof NavigationStart) {
                this._obtenerDatosService.desglose = [];
              }
          });
        }
    }

    ngOnInit(): void {
        // $('[data-toggle="tooltip"]').tooltip();
        this.loadData();
    }

    tryMigas(selectedContienda, params) {
      if (this._obtenerDatosService.main) {
        this._obtenerDatosService.establecerMigas(selectedContienda, params);}
      else {
        setTimeout(()=>{                           //<<<---using ()=> syntax
          this.tryMigas(selectedContienda, params);
        }, 500);
      }
    }

    loadData() {
        this._obtenerDatosService.loadMain()
        // this.globalService.inicializarContienda();
        this._obtenerDatosService.loadConfig().then(config => {
            this.titleService.setTitle(config['page_title']);

            this.config = config;
            // if (this.ga_created === false) {
            //   this.ga_created = true;
            //   console.log('this.globalService.rutaActual', this.globalService.rutaActual);
            //   ga('create', config['analytics_id'], 'auto');
            // }


            // 'G-8HZP3KWP6Y'
            // this.globalService.toggleBottomSheet(false);
          // setTimeout(() => {
          //   this.globalService.bottomSheetTpl = 'test';
          // }, 5000);
        });;
        // this._obtenerDatosService.loadMainCombos();
    }

    parametrosUrl(){
        /* Vaciamos el elemento siempre al incio */
        for (const key in  this.globalService.rutaParametros) {
            this.globalService.rutaParametros[key] = undefined;
        }
        /* Siempre ponemos la url en minusculas */
        this.globalService.rutaActual = this.router.url.toLowerCase();
        this.globalService.nivelesRutaActual = this.globalService.rutaActual.split('/').length;

        var array_paramters = this.globalService.rutaActual.split('/');
        var param = array_paramters.shift(), // el primero es vacio.
            param_casilla = '',
            param_seccion = '',
            param_distrito = '',
            param_municipio = '';

        this.globalService.menu = [];

        param = array_paramters.shift();
        if(param == 'gubernatura'){

          this.globalService.rutaParametros.nivelPagina = 'Gubernatura';

          param = array_paramters.shift();
          if(param == 'votos-candidatura'){
            this.globalService.menu = [
              { title: 'Votos por Candidatura', url: '/gubernatura/votos-candidatura/grafica', opt: 'votos-candidatura', active: true },
              { title: 'Votos por Partido Político y Candidatura Independiente Indígena', url: '/gubernatura/votos-ppyci/grafica', opt: 'votos-ppyci', active: false },
            ];
          }else if(param == 'votos-ppyci'){
            this.globalService.menu = [
              { title: 'Votos por Candidatura', url: '/gubernatura/votos-candidatura/grafica', opt: 'votos-candidatura', active: false },
              { title: 'Votos por Partido Político y Candidatura Independiente Indígena', url: '/gubernatura/votos-ppyci/grafica', opt: 'votos-ppyci', active: true },
            ];
          }else if(param == 'votos-extranjero'){
            // .
          }else{
            param_distrito = param;
            param = array_paramters.shift();


            if(param == 'votos-candidatura'){
              this.globalService.menu = [
                { title: 'Votos por Candidatura', url: '/gubernatura/'+param_distrito+'/votos-candidatura', opt: 'votos-candidatura', active: true },
                { title: 'Votos por Partido Político y Candidatura Independiente Indígena', url: '/gubernatura/'+param_distrito+'/votos-ppyci', opt: 'votos-ppyci', active: false },
                { title: 'Detalles por Casilla', url: '/gubernatura/'+param_distrito+'/detalle-casilla', opt: 'detalle-casilla', active: false },
              ];
            }else if(param == 'votos-ppyci'){
              this.globalService.menu = [
                { title: 'Votos por Candidatura', url: '/gubernatura/'+param_distrito+'/votos-candidatura', opt: 'votos-candidatura', active: false },
                { title: 'Votos por Partido Político y Candidatura Independiente Indígena', url: '/gubernatura/'+param_distrito+'/votos-ppyci', opt: 'votos-ppyci', active: true },
                { title: 'Detalles por Casilla', url: '/gubernatura/'+param_distrito+'/detalle-casilla', opt: 'detalle-casilla', active: false },
              ];
            }else if(param == 'detalle-casilla'){
              this.globalService.menu = [
                { title: 'Votos por Candidatura', url: '/gubernatura/'+param_distrito+'/votos-candidatura', opt: 'votos-candidatura', active: false },
                { title: 'Votos por Partido Político y Candidatura Independiente Indígena', url: '/gubernatura/'+param_distrito+'/votos-ppyci', opt: 'votos-ppyci', active: false },
                { title: 'Detalles por Casilla', url: '/gubernatura/'+param_distrito+'/detalle-casilla', opt: 'detalle-casilla', active: true },
              ];
            }else if(param == 'secciones'){
              param_seccion = array_paramters.shift();

              param = array_paramters.shift();
              if(param == 'casillas'){
                param_casilla = array_paramters.shift();
              }
            }

            // if(param == 'votos-candidatura'){
            //   this.globalService.menu = [
            //     { title: 'Votos por Candidatura', url: '/gubernatura/'+param_distrito+'/votos-candidatura', active: true },
            //     { title: 'Votos por Partido Político y Candidatura Independiente', url: '/gubernatura/'+param_distrito+'/votos-ppyci', active: false },
            //     { title: 'Detalles por Casilla', url: '/gubernatura/'+param_distrito+'/detalle-casilla', active: false },
            //   ];
            // }
          }

        }else if(param == 'diputaciones'){

          this.globalService.rutaParametros.nivelPagina = 'Diputación';

          param = array_paramters.shift();
          if(param == 'votos-distrito'){
            this.globalService.menu = [
              { title: 'Votos por Distrito', url: '/diputaciones/votos-distrito/mapa', opt: 'votos-candidatura', active: true },
              { title: 'Votos por Partido Político y Candidatura Independiente', url: '/diputaciones/votos-ppyci/grafica', opt: 'votos-ppyci', active: false },
            ];
          }else if(param == 'votos-ppyci'){
            this.globalService.menu = [
              { title: 'Votos por Distrito', url: '/diputaciones/votos-distrito/mapa', opt: 'votos-candidatura', active: false },
              { title: 'Votos por Partido Político y Candidatura Independiente', url: '/diputaciones/votos-ppyci/grafica', opt: 'votos-ppyci', active: true },
            ];
          }else if(param == 'votos-extranjero'){
            // .
          }else{
            param_distrito = param;
            param = array_paramters.shift();

            if(param == 'votos-candidatura'){
              this.globalService.menu = [
                { title: 'Votos por Candidatura', url: '/diputaciones/'+param_distrito+'/votos-candidatura', opt: 'votos-candidatura', active: true },
                { title: 'Votos por Partido Político y Candidatura Independiente', url: '/diputaciones/'+param_distrito+'/votos-ppyci', opt: 'votos-ppyci', active: false },
                { title: 'Detalles por Casilla', url: '/diputaciones/'+param_distrito+'/detalle-casilla', opt: 'detalle-casilla', active: false },
              ];
            }else if(param == 'votos-ppyci'){
              this.globalService.menu = [
                { title: 'Votos por Candidatura', url: '/diputaciones/'+param_distrito+'/votos-candidatura', opt: 'votos-candidatura', active: false },
                { title: 'Votos por Partido Político y Candidatura Independiente', url: '/diputaciones/'+param_distrito+'/votos-ppyci', opt: 'votos-ppyci', active: true },
                { title: 'Detalles por Casilla', url: '/diputaciones/'+param_distrito+'/detalle-casilla', opt: 'detalle-casilla', active: false },
              ];
            }else if(param == 'detalle-casilla'){
              this.globalService.menu = [
                { title: 'Votos por Candidatura', url: '/diputaciones/'+param_distrito+'/votos-candidatura', opt: 'votos-candidatura', active: false },
                { title: 'Votos por Partido Político y Candidatura Independiente', url: '/diputaciones/'+param_distrito+'/votos-ppyci', opt: 'votos-ppyci', active: false },
                { title: 'Detalles por Casilla', url: '/diputaciones/'+param_distrito+'/detalle-casilla', opt: 'detalle-casilla', active: true },
              ];
            }else if(param == 'secciones'){
              param_seccion = array_paramters.shift();

              param = array_paramters.shift();
              if(param == 'casillas'){
                param_casilla = array_paramters.shift();
              }
            }
          }

        } else if(param == 'ayuntamientos'){
          this.globalService.rutaParametros.nivelPagina = 'Ayuntamiento';

          param = array_paramters.shift();
          if(param == 'votos-municipio'){
            this.globalService.menu = [
              { title: 'Votos por Municipios', url: '/ayuntamientos/votos-municipio/mapa', opt: 'votos-candidatura', active: true },
              { title: 'Votos por Partido Político y Candidatura Independiente', url: '/ayuntamientos/votos-ppyci/grafica', opt: 'votos-ppyci', active: false },
            ];
          }else if(param == 'votos-ppyci'){
            this.globalService.menu = [
              { title: 'Votos por Municipios', url: '/ayuntamientos/votos-municipio/mapa', opt: 'votos-candidatura', active: false },
              { title: 'Votos por Partido Político y Candidatura Independiente', url: '/ayuntamientos/votos-ppyci/grafica', opt: 'votos-ppyci', active: true },
            ];
          }else if(param == 'votos-extranjero'){
            // .
          }else{
            param_distrito = param_municipio = param;
            param = array_paramters.shift();

            if(param == 'votos-candidatura'){
              this.globalService.menu = [
                { title: 'Votos por Candidatura', url: '/ayuntamientos/'+param_municipio+'/votos-candidatura', opt: 'votos-candidatura', active: true },
                { title: 'Votos por Partido Político y Candidatura Independiente', url: '/ayuntamientos/'+param_municipio+'/votos-ppyci', opt: 'votos-ppyci', active: false },
              ];
            }else if(param == 'votos-ppyci'){
              this.globalService.menu = [
                { title: 'Votos por Candidatura', url: '/ayuntamientos/'+param_municipio+'/votos-candidatura', opt: 'votos-candidatura', active: false },
                { title: 'Votos por Partido Político y Candidatura Independiente', url: '/ayuntamientos/'+param_municipio+'/votos-ppyci', opt: 'votos-ppyci', active: true },
              ];
            }else if(param == 'detalle-casilla'){
              this.globalService.menu = [
                { title: 'Votos por Distrito', url: '/ayuntamientos/'+param_municipio+'/votos-candidatura', opt: 'votos-candidatura', active: false },
                { title: 'Votos por Partido Político y Candidatura Independiente', url: '/ayuntamientos/'+param_municipio+'/votos-ppyci', opt: 'votos-ppyci', active: false },
              ];
            }else if(param == 'secciones'){
              param_seccion = array_paramters.shift();

              param = array_paramters.shift();
              if(param == 'casillas'){
                param_casilla = array_paramters.shift();
              }
            }
          }
        } else if(param == 'juntas'){
          this.globalService.rutaParametros.nivelPagina = 'Junta';

          param = array_paramters.shift();
          if(param == 'votos-municipio'){
            this.globalService.menu = [
              { title: 'Votos por Juntas', url: '/juntas/votos-municipio/mapa', opt: 'votos-candidatura', active: true },
              { title: 'Votos por Partido Político y Candidatura Independiente', url: '/juntas/votos-ppyci/grafica', opt: 'votos-ppyci', active: false },
            ];
          }else if(param == 'votos-ppyci'){
            this.globalService.menu = [
              { title: 'Votos por Juntas', url: '/juntas/votos-municipio/mapa', opt: 'votos-candidatura', active: false },
              { title: 'Votos por Partido Político y Candidatura Independiente', url: '/juntas/votos-ppyci/grafica', opt: 'votos-ppyci', active: true },
            ];
          }else if(param == 'votos-extranjero'){
            // .
          }else{
            param_distrito = param_municipio = param;
            param = array_paramters.shift();

            if(param == 'votos-candidatura'){
              this.globalService.menu = [
                { title: 'Votos por Candidatura', url: '/juntas/'+param_municipio+'/votos-candidatura', opt: 'votos-candidatura', active: true },
                { title: 'Votos por Partido Político y Candidatura Independiente', url: '/juntas/'+param_municipio+'/votos-ppyci', opt: 'votos-ppyci', active: false },
              ];
            }else if(param == 'votos-ppyci'){
              this.globalService.menu = [
                { title: 'Votos por Candidatura', url: '/juntas/'+param_municipio+'/votos-candidatura', opt: 'votos-candidatura', active: false },
                { title: 'Votos por Partido Político y Candidatura Independiente', url: '/juntas/'+param_municipio+'/votos-ppyci', opt: 'votos-ppyci', active: true },
              ];
            }else if(param == 'detalle-casilla'){
              this.globalService.menu = [
                { title: 'Votos por Distrito', url: '/juntas/'+param_municipio+'/votos-candidatura', opt: 'votos-candidatura', active: false },
                { title: 'Votos por Partido Político y Candidatura Independiente', url: '/juntas/'+param_municipio+'/votos-ppyci', opt: 'votos-ppyci', active: false },
              ];
            }else if(param == 'secciones'){
              param_seccion = array_paramters.shift();

              param = array_paramters.shift();
              if(param == 'casillas'){
                param_casilla = array_paramters.shift();
              }
            }
          }
        } else console.warn('La URL no pertenece a ninguna contienda.');

        this.globalService.param_casilla = param_casilla;
        this.globalService.param_seccion = param_seccion;
        this.globalService.param_distrito = param_distrito;
        this.globalService.param_municipio = param_municipio;


        // if(this.globalService.nivelesRutaActual === 3){
        //     if( this.globalService.rutaActual.indexOf('representacion-proporcional') !== -1){
        //         this.globalService.rutaParametros.nivelPagina = 'Gubernatura Representacion'
        //     }else{
        //         this.globalService.rutaParametros.nivelPagina = 'Gubernatura'
        //     }
        // }else if(this.globalService.nivelesRutaActual === 4){
        //     if( this.globalService.rutaActual.indexOf('representacion-proporcional') !== -1){
        //         this.globalService.rutaParametros.nivelPagina = 'Circunscripcion Representacion';
        //     }else{
        //         this.globalService.rutaParametros.nivelPagina = 'Circunscripcion'
        //     }


        //   //  this.obtenerCircunscripcion();
        // }else if(this.globalService.nivelesRutaActual === 5){
        //     /* Nivel Entidad */
        //     if( this.globalService.rutaActual.indexOf('mapa') !== -1 || this.globalService.rutaActual.indexOf('grafica') !== -1 || this.globalService.rutaActual.indexOf('tarjetas') !== -1) {
        //         this.globalService.rutaParametros.nivelPagina = 'Entidad';
        //         // this.obtenerCircunscripcion();
        //         // this.obtenerEntidad();
        //     }
        //     else if(this.globalService.rutaActual.indexOf('representacion-proporcional') !== -1){
        //         this.globalService.rutaParametros.nivelPagina = 'Entidad Representacion Proporcional';
        //         // this.obtenerCircunscripcion();
        //         // this.obtenerEntidad();
        //     }
        //      /* Nivel Distrito */
        //     else if(this.globalService.rutaActual.indexOf('votos-candidatura') !== -1 || this.globalService.rutaActual.indexOf('votos-ppyci') !== -1 || this.globalService.rutaActual.indexOf('detalle-casilla') !== -1){
        //         this.globalService.rutaParametros.nivelPagina = 'Distrito';
        //         // this.obtenerCircunscripcion();
        //         // this.obtenerEntidad();
        //         this.obtenerDistrito();
        //     }else if(this.globalService.rutaActual.indexOf('secciones') !== -1){
        //         this.globalService.rutaParametros.nivelPagina = 'Secciones Todas';
        //         // this.obtenerCircunscripcion();
        //         // this.obtenerEntidad();
        //         this.obtenerDistrito();
        //     }

        // }else if(this.globalService.nivelesRutaActual === 6){
        //     this.globalService.rutaParametros.nivelPagina = 'Seccion';
        //     // this.obtenerCircunscripcion();
        //     // this.obtenerEntidad();
        //     this.obtenerDistrito();
        //     this.obtenerSeccion();
        // }else if(this.globalService.nivelesRutaActual === 8){
        //     this.globalService.rutaParametros.nivelPagina = 'Casilla';
        //     // this.obtenerCircunscripcion();
        //     // this.obtenerEntidad();
        //     this.obtenerDistrito();
        //     this.obtenerSeccion();
        //     this.obtenerCasilla();
        // }
    }

    // obtenerCircunscripcion(){
    //     let separaUrl = this.globalService.rutaActual.split("/");
    //     this.globalService.rutaParametros.circunscripcion = separaUrl[1]
    //     this.globalService.rutaParametros.circunscripcionTexto = separaUrl[1].replace("circunscripcion","Circunscripción ")
    // }
    obtenerEntidad(){
        let separaUrl = this.globalService.rutaActual.split("/");
        let entidad = (separaUrl[2].toUpperCase()).replace(/-/g,"");
        if(this.globalService.diccionarioEntidadesURL.indexOf(entidad) !== -1){
            this.globalService.rutaParametros.noEntidad = this.globalService.diccionarioEntidadesURL.indexOf(entidad)
        }

        setTimeout(() => {
        }, 2000);
    }
    obtenerDistrito(){
        let separaUrl =  this.globalService.rutaActual.split("/");
        this.globalService.rutaParametros.distritoCompleto = separaUrl[3];
        this.globalService.rutaParametros.noDistrito = separaUrl[3];
        this.globalService.rutaParametros.distritoTexto = this.capitalize((separaUrl[3].replace(/distrito[0-9]/g,"")).replace(/-/g," "));
    }
    obtenerSeccion(){
        let separaUrl =  this.globalService.rutaActual.split("/");
        this.globalService.rutaParametros.noSeccion = separaUrl[5];
    }
    obtenerCasilla(){
        let separaUrl =  this.globalService.rutaActual.split("/");
        this.globalService.rutaParametros.casilla = separaUrl[7];
    }

    capitalize = (s) => {
        return s.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    }

    setStateParams(url) {
		let arr_url = url.split('/'),
			nivelPrincipal = ['mapa', 'tarjetas', 'grafica'],
			nivelHijos = ['votos-candidatura', 'votos-ppyci', 'detalle-casilla', 'secciones'];
		
		this.rutas.migas.distrito = '';
		this.rutas.migas.distrito_clave = '';
		this.rutas.migas.seccion = '';
		this.rutas.migas.seccion_clave = '';
		this.rutas.migas.casilla = '';
		this.rutas.migas.casilla_clave = '';

		if (arr_url[1]) localStorage.setItem('contienda', arr_url[1]);

		if (arr_url[3] && (nivelPrincipal.includes(arr_url[3]))) { // ****** SON VISTAS PRINCIPALES
        // this.rutas.migas.distrito = '';
        // this.rutas.migas.distrito_clave = '';
        // this.rutas.migas.seccion = '';
        // this.rutas.migas.seccion_clave = '';
        // this.rutas.migas.casilla = '';
        // this.rutas.migas.casilla_clave = '';
    	} else if (arr_url[3] && (nivelHijos.includes(arr_url[3]))) {
    		if (arr_url[3] == 'secciones') {
				if (arr_url[4]) { // ******* ESTAN MINIMO A NIVEL DETALLE DE SECCION
					this.rutas.migas.distrito_clave = arr_url[2];
					this.rutas.migas.seccion_clave = arr_url[4];


					if (arr_url[5]) this.rutas.migas.casilla_clave = arr_url[6]; // ******* ESTAN A NIVEL CASILLA
				} else { // ******* ESTAN A NIVEL GENERAL DE SECCIONES
					this.rutas.migas.distrito_clave = arr_url[2];
					this.rutas.migas.seccion_clave = 'Todas';
				}
			} else this.rutas.migas.distrito_clave = arr_url[2]; // ******* ESTAN A NIVEL DISTRITO
		}

		// this._obtenerDatosService.urlSubject.next(this.rutas.migas);
		// this._obtenerDatosService.cargarIndices();
		this.tryIndices();
	}

	tryIndices() {
		if (this._obtenerDatosService.main && this._obtenerDatosService.main != {}) this._obtenerDatosService.cargarIndices();
		else {
		  setTimeout(()=>{                           //<<<---using ()=> syntax
			this.tryIndices();
		  }, 500);
		}
	  }

    obtenerRutaActual(rutaCompleta: string) {
        this.globalService.setearParametros();
        if (rutaCompleta.split('/').slice(3, 4).join('/')) {
            this.rutas.rutaActual.ruta = rutaCompleta.split('/').slice(1).join('/');
            this.rutas.nivelRutaEspecifica = true;
        } else {
            this.rutas.rutaActual.ruta = rutaCompleta;
            this.rutas.nivelRutaEspecifica = false;
            this.globalService.detalles = {};
        }
        // this.rutas.rutaActual.base = '/' + rutaCompleta.split('/').slice(1, 2).join('/');
        const ultimoElemento = rutaCompleta.split('/').slice(-1)[0];

        if (ultimoElemento == 'mapa' || ultimoElemento == 'grafica' || ultimoElemento == 'tarjetas') {
            this.rutas.rutaActual.base = rutaCompleta.split('/').slice(-2, -1)[0];
        } else {
            if(this.globalService.detalles.casilla) {
                this.rutas.rutaActual.base = 'casilla';
            } else {
                const el = ultimoElemento.toLocaleLowerCase().replace(/[ 0-9]/g, '');
                this.rutas.rutaActual.base = el;
            }
        }
    }

    verificarNivel() {

    }


}
