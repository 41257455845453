import { Component, OnInit, Input } from '@angular/core';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from "src/app/servicios/globales.service";
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { ThemeService } from '../../../../../../theme/theme.service';

@Component({
  selector: 'app-detalle-votos-casilla',
  templateUrl: './detalle-votos-casilla.component.html',
  styleUrls: ['./detalle-votos-casilla.component.scss']
})
export class DetalleVotosCasillaComponent implements OnInit {

    contiendaActual: string;
    param: string;
    datosAvance: any = [];
    @Input() showCATD: boolean;
    showLabel = false;
    actasRoot = '';

    constructor(
        public _obtenerDatosService: ObtenerDatosService,
        public _globalService : GlobalesService,
        public rutas: RutasService,
        public themeService: ThemeService
    ) { }

    ngOnInit(): void {
        this._obtenerDatosService.configSubject.subscribe(data =>{
            if(data == null) return;
            this.showLabel = data.estado == "Veracruz"
        });
        this.contiendaActual = this._globalService.getContienda();
        this.param = this._globalService.param_distrito;

        this._obtenerDatosService.mainSubject.subscribe(data =>{
            if(data == null) return;

            this.actasRoot = data?.actas_root ? data?.actas_root : ""
          });
    }
    
    scrollComponent( component : string ){
        var el = document.getElementById(component);
        if(el){
            el.scrollIntoView();
        }
    }
//    scrollComponent(el: HTMLElement) {
//        el.scrollIntoView();
//    }
  

}
