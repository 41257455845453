<div class="casilla-resumen container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">

                <div class="container-fluid barra-lateral d-block d-md-block d-xl-none mb-4">
                    <div class="pt-2 dis-flex pb-2">
                        <button (click)="atras()" class="btn btn-sm btn-azulFuerte px14 btnBack">
                            Regresar
                        </button>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-10">
                        <h3 class="titulo-seccion">
                            Gubernatura - <span class="txRosaIne"><strong>Voto en el extranjero</strong></span>
                        </h3>
                        <h4 style="font-size: 1.9em;">{{_obtenerDatosService?.config?.estado}}</h4>
                    </div>
                    <div class="col-12 col-lg-10 mb-4">
                        <div class="texto font-regular d-block">
                            <p>El total de votos calculado y porcentaje que se muestran, se refiere a los votos asentados desde el Extranjero en el Acta de Cómputo entidad Federativa hasta el momento.</p>
                            <!-- <br class="d-block d-xl-none"> -->
                            <!-- <p>Por presentación, los decimales de los porcentajes muestran sólo cuatro digitos. No obstante, al considerar todos los decimales, suman 100%</p> -->
                        </div>
                    </div>
                </div>
                
                <div class="d-block d-xl-none mapa-puntos">
                    <app-resumen-votacion-mobile
                        [total_num]="resumen?.total?.total"
                        [total_prc]="resumen?.total?.prc"
                        [hide_prc]="true">
                    </app-resumen-votacion-mobile>
                </div>
                    
                <div class="row pb-4 d-none d-md-none d-xl-block">
                    <div class="col mapa-puntos">

                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Acta Contabilizada</strong>
                        </h4>
                        <div class="acta-digitalizada container-fluid">
                            <div class="row">
                                <div class="col-8 pl-0">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-6 border-rightB padding-r-0">
                                                <p><strong>Imagen procedente de Acta de Cómputo Entidad Federativa:</strong></p>
                                                <a target="_blank" [href]="actasRoot + (status?.scanner ? status?.scanner : (status?.movil ? status?.movil : ''))" *ngIf="(status?.scanner || status?.movil)">
                                                    <button
                                                        class="btn btn-sm btn-rosaFuerte texto cursorPointer my-3 px-4 py-1">
                                                        <i class="far fa-file mr-1"></i>
                                                        Acta
                                                    </button>
                                                </a>
                                                <button *ngIf="(!status?.scanner && !status?.movil)" disabled
                                                    class="btn btn-sm btn-gris texto cursorPointer my-3 px-4 py-1">
                                                    <i class="far fa-file mr-1"></i>
                                                    <i class="far fa-hourglass mr-1"></i>
                                                    Acta en Proceso
                                                </button>
                                            </div>
                                            <div class="col-6">
                                                <p class="font-medium text-left padding-b-5">Imagen procedente de:</p>
                                                <div class="row">
                                                    <div class="col-6 dis-flex border-rightB padding-r-0">
                                                        <div class="grupo gris dis-flex">
                                                            <i class="far fa-file icon-size"></i>
                                                            <i class="far fa-hourglass icon-size"></i>
                                                            <p>
                                                                Acta en proceso
                                                            </p>
                                                            <div class="vline"></div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 dis-flex">
                                                        <div class="grupo gris dis-flex" placement="top"
                                                            max-width="500">
                                                            <i class="far fa-file purpura icon-size icon-color"></i>
                                                            <p>
                                                                Acta de Cómputo Entidad Federativa
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row d-none d-block d-md-block d-xl-none">
                                <div class="col-8 pl-0">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="dis-flex border-rightB padding-r-0">
                                                <a target="_blank" [href]="actasRoot + (status?.scanner ? status?.scanner : (status?.movil ? status?.movil : ''))" *ngIf="(status?.scanner || status?.movil)">
                                                    <button
                                                        class="btn btn-sm btn-rosaFuerte texto cursorPointer my-3 px-4 py-1">
                                                        <i class="far fa-file mr-1"></i>
                                                        Acta
                                                    </button>
                                                </a>
                                                <button *ngIf="(!status?.scanner && !status?.movil)" disabled
                                                    class="btn btn-sm btn-gris texto cursorPointer my-3 px-4 py-1">
                                                    <i class="far fa-file mr-1"></i>
                                                    <i class="far fa-hourglass mr-1"></i>
                                                    Acta en Proceso
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--************************ TARJETAS EN ESCRITORIO ***********************-->
                <div class="container-fluid d-none d-md-none d-xl-block">
                    <div class="row dis-flex pb-1">
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollLeft()">
                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                        </div>
                        <div class="col-11">
                            <div #widgetsContent class="row dis-flex justify-s scrollH">
                                <div *ngFor="let tarjeta of fichas; let i=index"
                                    class="col-6 col-sm-4 col-md-3 col-lg-2 px-1">
                                    <div class="card-resumen">
                                        <div class="encabezado dis-flex w-100 py-2">
                                            <div class="dis-flex w-100">
                                                <div class="row w-100 dis-flex">
                                                    <img *ngFor="let img of tarjeta?.imgs" class="img-partido" src="./assets/img/logos-partidos/{{img}}">
                                                    <!-- <p class="pe p-1" *ngFor="let img of tarjeta?.imgs" [ngStyle]="{
                                                        'background-image': 'url(./assets/img/logos-partidos/'+  img +')',
                                                        'background-size': 'contain',
                                                        'background-repeat': 'no-repeat',
                                                        'background-position': 'center',
                                                        'margin': '20px 2px'
                                                    }">
                                                    </p> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cuerpo dis-flex bgGrisTenue p-2">
                                            <div class="w-50">
                                                <p>Votos</p>
                                                <p class="votos">
                                                    <strong>{{ tarjeta?.total }}</strong>
                                                </p>
                                            </div>
                                            <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                                <!-- <p>&nbsp;</p>
                                                <p>{{ tarjeta?.porcentaje}}</p> -->
                                                <p class="estadistica-label" >&nbsp;</p>
                                                <p class="estadistica-val">&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollRight()">
                            <i class="fas fa-chevron-right" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <!--********************************************************************************************************************-->
                <app-resumen-votacion class="d-none d-xl-block"
                    [extranjero_num]="resumen?.extranjero?.total"
                    [extranjero_prc]="resumen?.extranjero?.prc"
                    [hide_acumulados]="true"
					[acumulados_num]="resumen?.acumulados?.total"
					[acumulados_prc]="resumen?.acumulados?.prc"
					[noregistradas_num]="resumen?.no_registradas?.total"
					[noregistradas_prc]="resumen?.no_registradas?.prc"
					[nulos_num]="resumen?.nulos?.total"
					[nulos_prc]="resumen?.nulos?.prc"
					[total_num]="resumen?.total?.total"
					[total_prc]="resumen?.total?.prc"
                    [use_observaciones]="true"
                    [hide_prc]="true"
                    [hide_legend]="true"
                ></app-resumen-votacion>
                
            </div>
        </div>
    </div>
    
    <!--********************* TABLA MOVIL *********************-->
    <div class="row d-block d-md-block d-xl-none">
        <div class="col-12">
            <h4 class="subtitulo-seccion pleca-izquierda">
                <strong>Votos en Acta Contabilizada</strong>
            </h4>
            <div class="row">
                <div class="col-12 ml-3 padding-r-0">
                    <p>Imagen procedente de Acta de Cómputo Entidad Federativa:</p>
                    <a target="_blank" [href]="actasRoot + (status?.scanner ? status?.scanner : (status?.movil ? status?.movil : ''))" *ngIf="(status?.scanner || status?.movil)">
                        <button class="btn btn-sm btn-rosaFuerte texto cursorPointer mb-3 mt-1 px-4 py-1">
                            <i class="far fa-file mr-1"></i>
                            Ver
                        </button>
                    </a>
                    <button *ngIf="(!status?.scanner && !status?.movil)" disabled
                            class="btn btn-sm btn-gris texto cursorPointer mb-3 mt-1 px-4 py-1">
                        <i class="far fa-file mr-1"></i>
                        <i class="far fa-hourglass mr-1"></i>
                        Ver
                    </button>
                </div>
            </div>
        </div>
        <div class="row mb-5" *ngIf="selectedOptionIndx == -1">
            <div class="w-100 dis-flex table-movil py-2 pl-4" *ngFor="let item of fichas; let indx = index;" >
                <table class="w-100 ">
                    <tr>
                        <td class="w-col">
                            <img class="logo" height="30px" *ngIf="item.imgs?.length > 0" 
                                src="./assets/img/logos-partidos/{{item.imgs[0]?.toLowerCase()}}"/>
                        </td>
                        <td class="w-col">
                            <p class="label-votos align-center"><br>&nbsp;</p>
                        </td>
                        <td class="w-col">
                            <p class="label-votos">Votos</p>
                            <p class="votos"><strong>{{ item?.total }}</strong></p>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <app-resumen-votacion class="d-block d-xl-none"
        [acumulados_num]="resumen?.acumulados?.total"
        [acumulados_prc]="resumen?.acumulados?.prc"
        [extranjero_num]="resumen?.acumulados?.total"
        [extranjero_prc]="resumen?.extranjero?.prc"
        [hide_acumulados]="true"
        [noregistradas_num]="resumen?.no_registradas?.total"
        [noregistradas_prc]="resumen?.no_registradas?.prc"
        [nulos_num]="resumen?.nulos?.total"
        [nulos_prc]="resumen?.nulos?.prc"
        [total_num]="resumen?.total?.total"
        [total_prc]="resumen?.total?.prc"
        [hide_prc]="true"
        [extranjero]="true"
        [use_acumulados]="true"
    ></app-resumen-votacion>
    <!--*****************************************************************-->
    <!--    <div class="card p-3">-->
    <div class="row">
        <div class="col-md-12">
            <div class="estadistica-casilla-movil card mt-3 p-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <h4 class="subtitulo-seccion pleca-izquierda">
                                <strong>Estadística de Mesa de Escrutinio</strong>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="container-fluid d-none d-xl-block pt-3">
                    <div class="row">
                        <div class="col">
                            <p class="subtitulo underline">
                                <strong>Participación ciudadana con base en la Lista Nominal del Acta PREP Contabilizada</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container-fluid d-none d-xl-block">
                    <div class="row my-2">
                        <div class="col-12">
                            <div class="grafica">
                                <div class="textos" style="display: flex;">
                                    <div class="w-50">
                                        <p class="estadistica-label" >Participación ciudadana</p>
                                        <p class="estadistica-val"><strong>{{estadistica_extranjero.participacion}}</strong></p>
                                    </div>
                                    <div class="w-50">
                                        <p class="estadistica-label"  style="text-align: right;"><strong>Total de la Lista Nominal</strong></p>
                                        <p class="estadistica-val" style="text-align: right;"><strong>{{estadistica_extranjero.total_ln}}</strong></p>
                                    </div>
                                </div>
                                <div class="container-interno">
                                        <div class="barra" [ngStyle]="{ 'width.': estadistica_extranjero.participacion}"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <movil-estadistica-content
                [tipoEstadistica]="4"
                [infoPorcentaje]="estadistica_extranjero.participacion"
                [listaNominal]="estadistica_extranjero.total_ln"
                nombreCasilla="Total de la Lista Nominal"
            ></movil-estadistica-content>
        </div>
    </div>
    <!--    </div>-->
</div>