import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObtenerDatosService } from 'src/app/servicios/datos/obtener-datos/obtener-datos.service';
import { GlobalesService } from 'src/app/servicios/globales.service';
import { RutasService } from 'src/app/servicios/rutas/rutas.service';
import { ThemeService } from 'src/app/theme/theme.service';

// declare var $;
// import * as $ from 'jquery';

@Component({
  selector: 'app-casilla-resumen',
  templateUrl: './casilla-resumen.component.html',
  styleUrls: ['./casilla-resumen.component.scss']
})
export class CasillaResumenComponent implements OnInit {

  base: string;
  seccion: string;
  objCasilla: any;
  showCATD = false
  showLabel = false
  showEscaner = true
  showMovil = true
  showConfCatd = true
  actasRoot = ""

  constructor(
    public _globalService: GlobalesService,
    private readonly activatedRouter: ActivatedRoute,
    public readonly rutas: RutasService,
    public _obtenerDatosService: ObtenerDatosService,
    public router: Router,
    public themeService: ThemeService,
  ) { }

  ngOnInit(): void {
    // this.obtenerParametros();
    this.base = this.rutas.rutaActual.base;
    this.rutas.rutaActual.base = 'casilla';
    // $('[data-toggle="tooltip"]').tooltip();


    this._obtenerDatosService.configSubject.subscribe(data =>{
      if(data == null) return;
      this.showLabel = data.estado == "Veracruz"
      this.showEscaner = data.show_escaner;
      this.showMovil = data.show_movil;
      this.showConfCatd = data.show_catd;
    });
    this._obtenerDatosService.mainSubject.subscribe(data =>{
      if(data == null) return;

      this.loadData();
      this.showCATD = data?.show_catd ? data?.show_catd : false
      this.actasRoot = data?.actas_root ? data?.actas_root : ""
      console.log("ACTAAS ROOT: ",this.actasRoot);
    });
  }

  loadData() {
    let selectedContienda = this._globalService.getContienda();
      this._obtenerDatosService.loadJSON(selectedContienda, this.rutas.migas.seccion_clave + '.' + this.rutas.migas.distrito_clave + '.casilla.json').then(data => {
      // if (this.rutas.migas.casilla_clave)
      // console.log('this.rutas.migas.casilla_clave', this.rutas.migas.casilla_clave);
      // console.log(data);
      if (data) {
        let proceso = false,
            existe = false;
        for (let i = 0; i < data['casilla_table'].rows.length; i++) {
          const element = data['casilla_table'].rows[i];
  
          // console.log('element', element);
          if (element.param == this.rutas.migas.casilla_clave) {
			  existe = true;
            this.rutas.migas.casilla = (element.text_alt ? element.text_alt : element.text);

            if (element.status == 'proceso') proceso = true;
          }
        }

        // console.log('proceso', proceso);
  
        //if (existe === false || proceso || (data['casillas'][this.rutas.migas.casilla_clave] && data['casillas'][this.rutas.migas.casilla_clave].status == 'proceso')) this.router.navigateByUrl(`${selectedContienda}/${this.rutas.migas.distrito_clave}/secciones/${this.rutas.migas.seccion_clave}`);
        this.objCasilla = data['casillas'][this.rutas.migas.casilla_clave];
          // console.log(this.objCasilla.status)
      } //else if ((data['casillas'][this.rutas.migas.casilla_clave] && data['casillas'][this.rutas.migas.casilla_clave].status == 'proceso')) this.router.navigateByUrl(`${selectedContienda}/${this.rutas.migas.distrito_clave}/secciones/${this.rutas.migas.seccion_clave}`);
    });
    // }
  }

  obtenerParametros() {
    this.activatedRouter.params.subscribe((parametros) => {
      const params = { parametros };
      this._globalService.detalles = params.parametros;
    });
    this._globalService.setearParametros();
    this.seccion = this.rutas.migas?.seccion.split(' ')[1];
  }



  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

}
