<div class="container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-xl-10">
                        <app-breadcrumb subtitle="Entidad"
                            subtitle_detalle="Votos por Partido Político y Candidatura Independiente Indígena"
                            [estado]="_obtenerDatosService?.config?.estado"></app-breadcrumb>
                        <div class="texto font-regular">
                            <p>
                                El total de votos calculado y porcentaje que se muestran, se refiere a los votos asentados en las Actas PREP hasta el momento.
                            </p>
                            <p>
                                Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No
                                obstante, al
                                considerar todos los decimales, suman 100%.
                            </p>
                        </div>
                    </div>

                    <div class="col-2 d-none d-xl-block" *ngIf="_obtenerDatosService?.config?.estado == 'Veracruz'">
                        <p class="px12 text-left"><strong>Visualiza como:</strong></p>
                        <div class="visualizar dis-flex flex-col text-center">
                            <div class="selector dis-flex w-100 cursorPointer">
                                <div [routerLink]="['../grafica']" class="izq dis-flex flex-col w-50 p-2">
                                    <img *ngIf="!themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Grafica-A.svg" style="opacity: 0.5;">
                                    <img *ngIf="themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Grafica-A-dark.svg" style="opacity: 0.5;">
                                    <!-- <i class="ine ine-mapa" aria-hidden="true"></i> -->
                                    <p>Gráfica</p>
                                </div>
                                <div class="activo dis-flex der flex-col w-50 p-2">
                                    <img *ngIf="!themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-A.svg">
                                    <img *ngIf="themeService.isDarkTheme()" class="img-visualiza img-fluid" src="assets/img/iconos-visualiza/Tarjetas-B.svg">
                                    <!-- <i class="ine ine-tarjetas" aria-hidden="true"></i> -->
                                    <p>Tarjetas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <p class="p-3" style="font-size: 1.4em; text-align: justify;">Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%.</p>

                <app-resumen-votacion-mobile
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc"
                ></app-resumen-votacion-mobile>

                <div class="d-block d-xl-none" *ngIf="!omitirExtranjero">
                    <p class="movil-titulo-votos">Total de votos en:</p>
                    <div class="dis-flex ml-2">
                        <div class="w-50 votos-container ml-3 mr-1">
                            <p class="label-tipo ml-1"><strong>La Entidad</strong></p>
                            <p class="label-cantidad mr-2">{{resumen?.acumulados?.total}}</p>
                        </div>
                        <div class="w-50 votos-container mr-2">
                            <p class="label-tipo ml-1"><strong>El Extranjero</strong></p>
                            <p class="label-cantidad mr-2">{{resumen?.extranjero?.total}}</p>
                        </div>
                    </div>
                </div>

                <div class="row pb-3 mt-2">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Actas Contabilizadas</strong>
                        </h4>
                    </div>
                </div>
                <!-- TARJETAS DE ESCRITORIO -->
                <div class="container-fluid d-none d-md-none d-xl-block">
                    <div class="row justify-content-center">
                        <div class="col-8 col-sm-4 col-md-3 col-lg-2"
                            *ngFor="let tarjeta of fichas; let indx = index;">
                            <div class="tarjeta card dis-flex flex-col mb-2"
                                [ngClass]="{cardGanador: tarjeta?.header?.winner}">
                                <img class="logo" *ngIf="tarjeta?.imgs?.length > 0"
                                    src="./assets/img/logos-partidos/{{tarjeta?.imgs[0]?.toLowerCase()}}">
                                <div class="cuerpo w-100 p-2">
                                    <div class="w-100"><p><strong>Total de Votos</strong></p></div>
                                    <div class="w-100 dis-flex">
                                        <div class="w-50">
                                            <p class="votos">{{ tarjeta?.total }}</p>
                                        </div>
                                        <div class="w-50 dis-flex flex-col align-e">
                                            <p>{{ tarjeta?.porcentaje }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="cuerpo bgGrisTenue w-100 p-2" *ngIf="!omitirExtranjero">
                                    <div class="w-100"><p><strong>Votos En la Entidad</strong></p></div>
                                    <div class="w-100 dis-flex">
                                        <div class="w-50">
                                            <p class="votos">{{ tarjeta?.votos_entidad }}</p>
                                        </div>
                                        <div class="w-50 dis-flex flex-col align-e">
                                            <p>{{ tarjeta?.porcentaje_entidad }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="cuerpo bgGrisTenue w-100 p-2 separador" *ngIf="!omitirExtranjero">
                                    <div class="w-100"><p><strong>En el Extranjero</strong></p></div>
                                    <div class="w-100 dis-flex">
                                        <div class="w-50">
                                            <p class="votos">{{ tarjeta?.votos_extranjero }}</p>
                                        </div>
                                        <div class="w-50 dis-flex flex-col align-e">
                                            <p>{{ tarjeta?.porcentaje_extranjero }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!tarjeta.seleccionado" (click)="agregarElemento(tarjeta);"
                                    class="pie w-100 dis-flex bgMorado txBlanco p-2 cursorPointer">
                                    <p>Seleccionar</p>
                                </div>
                                <div *ngIf="tarjeta.seleccionado" (click)="eliminarElemento(tarjeta);"
                                    class="pie w-100 dis-flex bgMorado txBlanco p-2 cursorPointer seleccionado">
                                    <p>Quitar selección</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TARJETAS MOVIL -->
                <movil-listado-content 
                    [selectedItemFunc]="selectedItemFun" 
                    [diselectedItemFunc]="diselectedItemFun"
                    [infoList]="fichas" 
                    [tipoTarjeta]="2"></movil-listado-content>
                <!--********************************************************************************************************************-->
                <div class="container-fluid">
                    <div class="row dis-flex">
                        <div class="col-md-12">
                            <div class="row dis-flex justify-s">

                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="container-fluid py-4 d-none d-xl-block">
                    <div class="row">
                        <div class="col-md-12 dis-flex justify-e">
                            <i class="fas fa-info-circle mr-2" aria-hidden="true"></i>
                            <p class="texto">
                                Da clic en <strong>Seleccionar</strong> para visualizar el detalle de la votación.
                                Puedes seleccionar
                                hasta tres opciones.
                            </p>
                            <button [disabled]="comparativaService?.arregloElementos.length < 1"
                                (click)="comparativaService.reiniciarModal()"
                                class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer"
                                data-toggle="modal" data-target="#modalComparativa">
                                Ver detalle
                            </button>
                        </div>
                    </div>
                </div> -->

                <app-resumen-votacion extranjero="true"

                    [acumulados_num]="resumen?.acumulados?.total"
                    [acumulados_prc]="resumen?.acumulados?.prc"
                    [extranjero_num]="resumen?.extranjero?.total"
                    [extranjero_prc]="resumen?.extranjero?.prc"
                    [noregistradas_num]="resumen?.no_registradas?.total"
                    [noregistradas_prc]="resumen?.no_registradas?.prc"
                    [nulos_num]="resumen?.nulos?.total"
                    [nulos_prc]="resumen?.nulos?.prc"
                    [total_num]="resumen?.total?.total"
                    [total_prc]="resumen?.total?.prc">
                </app-resumen-votacion>

                <!-- <div class="container-fluid py-4 d-block d-md-block d-xl-none">
                    <div class="row">
                        <div class="col-md-12 dis-flex justify-e">
                            <button [disabled]="comparativaService?.arregloElementos.length < 1"
                                (click)="comparativaService.reiniciarModal()"
                                class="boton-detalle btn bgMorado txBlanco btn-sm texto ml-2 px-4 cursorPointer"
                                data-toggle="modal" data-target="#modalComparativa">
                                Ver detalle
                            </button>
                        </div>
                    </div>
                </div> -->

            </div>

            <div class="row" *ngIf="!omitirExtranjero">
                <div class="col-md-12">
                    <div class="card p-3 mt-3 mapa-puntos">
                        <div class="row justify-content-center">
                            <div class="col-12 col-xl-4 text-center">
                                <i class="fas fa-globe-americas"></i>
                                <h4 class="subtitulo-seccion py-3 text-center">Voto en el Extranjero</h4>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-6 col-xl-4 dis-flex">
                                <button [routerLink]="['/gubernatura/votos-ppyci/voto-extranjero']"
                                    class="btn btn-sm btn-rosaFuerte texto cursorPointer my-3 px-4 py-1">
                                    Ver Resultados
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<app-estadistica-nacional [load_main]="true"></app-estadistica-nacional>