<div class="casilla-resumen container-fluid">
    <div class="row">
        <div class="col-md-12">
            <div class="card p-3">
                <div class="row">
                    <div class="col-12 col-lg-10">
                        <app-breadcrumb
                            subtitle_detalle="Sección - Casilla"
                            [estado]="_obtenerDatosService?.config?.estado"
                            [distrito]="rutas.migas?.distrito"
                            [distrito_param]="rutas.migas?.distrito_clave"
                            [seccion]="rutas.migas?.seccion"
                            [seccion_param]="rutas.migas?.seccion_clave"
                            [casilla]="rutas.migas?.casilla ? (rutas.migas?.casilla) : 'Casilla Default'"
                        ></app-breadcrumb> 

                        <div class="texto font-regular">
                            <p>
                                El total de votos calculado y porcentaje que se muestran, se refieren a los votos asentados en el Acta PREP hasta el momento.
                            </p>
                            <!-- <br *ngIf="_obtenerDatosService?.config?.estado == 'Veracruz'" class="d-block d-xl-none" />
                            <p *ngIf="_obtenerDatosService?.config?.estado == 'Veracruz'">
                                Por presentación, los decimales de los porcentajes muestran solo cuatro dígitos. No obstante, al considerar todos los decimales, suman 100%
                            </p> -->
                        </div>
                    </div>
                </div>
                <app-resumen-votacion-mobile
                    [total_num]="objCasilla?.resumen?.total?.total"
                    [total_prc]="objCasilla?.resumen?.total?.prc"
                    [hide_prc]="true"
                ></app-resumen-votacion-mobile>
                <div class="row pb-4">
                    <div class="col">
                        <h4 class="subtitulo-seccion pleca-izquierda">
                            <strong>Votos en Acta Contabilizada</strong>
                        </h4>
                    </div>
                </div>
                <div class="acta-digitalizada container-fluid pb-3">
                    <div class="row d-none d-none d-xl-flex">
                        <div class="col-4 col-xl-2 border-right">
                            <p class="pb-3">Acta digitalizada:</p>
                            <a *ngIf="(objCasilla?.status?.scanner || objCasilla?.status?.movil || objCasilla?.status?.catd)" target="_blank" [href]="actasRoot + (objCasilla?.status?.scanner ? objCasilla?.status?.scanner : (objCasilla?.status?.movil ? objCasilla?.status?.movil : (objCasilla?.status?.catd ? objCasilla?.status?.catd : '')))">
                                <button class="btn boton-rosa txBlanco custom-wd" [disabled]="(!objCasilla?.status?.scanner && !objCasilla?.status?.movil && !objCasilla?.status?.catd)">
                                    <div style="display: inline-flex;">
                                        <i *ngIf="!objCasilla?.status?.catd" class="far fa-file" aria-hidden="true"></i>
                                        <i *ngIf="!objCasilla?.status?.catd" aria-hidden="true" [ngClass]="{fas: (objCasilla?.status?.scanner || objCasilla?.status?.movil), 'far': (!objCasilla?.status?.scanner && !objCasilla?.status?.movil), 'fa-print' : objCasilla?.status?.scanner, 'fa-mobile-alt': (objCasilla?.status?.movil && !objCasilla?.status?.scanner), 'fa-hourglass': (!objCasilla?.status?.scanner && !objCasilla?.status?.movil)}"></i>
                                        <img *ngIf="objCasilla?.status?.catd" src="./assets/img/generales/movil_catd_white.svg" height="16" style="margin-right: 4px;">
                                        <span *ngIf="objCasilla?.status && objCasilla?.status != 'sin_acta'">&nbsp;Ver</span>
                                        <span *ngIf="objCasilla?.status && objCasilla?.status == 'sin_acta'">&nbsp;Sin Acta</span>
                                    </div>
                                </button>
                            </a>
                            <button *ngIf="(!objCasilla?.status?.scanner && !objCasilla?.status?.movil && !objCasilla?.status?.catd)" class="btn boton-rosa txBlanco custom-wd" [disabled]="(!objCasilla?.status?.scanner && !objCasilla?.status?.movil && !objCasilla?.status?.catd)">
                                <div style="display: inline-flex;">
                                    <i *ngIf="!objCasilla?.status?.catd" class="far fa-file" aria-hidden="true"></i>
                                    <i *ngIf="!objCasilla?.status?.catd" aria-hidden="true" [ngClass]="{fas: (objCasilla?.status?.scanner || objCasilla?.status?.movil), 'far': (!objCasilla?.status?.scanner && !objCasilla?.status?.movil), 'fa-print' : objCasilla?.status?.scanner, 'fa-mobile-alt': (objCasilla?.status?.movil && !objCasilla?.status?.scanner), 'fa-hourglass': (!objCasilla?.status?.scanner && !objCasilla?.status?.movil)}"></i>
                                    <img *ngIf="objCasilla?.status?.catd" src="./assets/img/generales/movil_catd_white.svg" height="16" style="margin-right: 4px;">
                                    <span *ngIf="objCasilla?.status && objCasilla?.status != 'sin_acta'">&nbsp;Ver</span>
                                    <span *ngIf="objCasilla?.status && objCasilla?.status == 'sin_acta'">&nbsp;Sin Acta</span>
                                </div>
                            </button>
                        </div>
                        <div class="col-8 col-xl-6">
                            <p class="font-medium pb-3">
                                Imagen procedente de:
                            </p>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-4 dis-flex border-rightB padding-r-0">
                                        <div class="grupo gris dis-flex">
                                            <i class="far fa-file"></i>
                                            <i class="far fa-hourglass"></i>
                                            <p>
                                                Acta en proceso
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-2 dis-flex border-rightB padding-r-0 padding-l-0" *ngIf="showEscaner">
                                        <div class="grupo dis-flex cursor-hand" tooltip="Acta PREP digitalizada por medio de escáner." placement="top" max-width="500">
                                            <i class="far fa-file"></i>
                                            <i class="fas fa-print" aria-hidden="true"></i>
                                            <p>
                                                Escáner
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-3 dis-flex" *ngIf="showMovil">
                                        <div class="grupo dis-flex cursor-hand" tooltip="Acta PREP digitalizada por medio de dispositivo móvil." placement="top" max-width="500">
                                            <i class="far fa-file"></i>
                                            <i class="fas fa-mobile-alt"></i>
                                            <p>
                                                Móvil {{showLabel ? "Casilla" : ''}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-3 dis-flex" *ngIf="showCATD && showConfCatd">
                                        <div class="grupo dis-flex cursor-hand" tooltip="Acta PREP digitalizada por medio de dispositivo móvil."placement="top" max-width="500">
                                            <div class="grupo dis-flex">
                                                <div *ngIf="!themeService.isDarkTheme()" style="width: 29px;height: 20px;background-image: url('./assets/img/generales/logo_movil_catd.png');background-repeat: no-repeat;image-rendering: -webkit-optimize-contrast;background-size:contain;"></div>
                                                <div *ngIf="themeService.isDarkTheme()" style="width: 29px;height: 20px;background-image: url('./assets/img/generales/logo_movil_catd_rosa.png');background-repeat: no-repeat;image-rendering: -webkit-optimize-contrast;background-size:contain;"></div>
                                                <p class="leyenda">
                                                    Móvil CATD
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row d-block d-md-block d-xl-none">
                        <div class="col-6 col-xl-6 border-right">
                            <p class="pb-3">Ver Acta digitalizada en:</p>
                            <a *ngIf="(objCasilla?.status?.scanner || objCasilla?.status?.movil || objCasilla?.status?.catd)"
                                target="_blank" [href]="actasRoot + (objCasilla?.status?.scanner ? objCasilla?.status?.scanner : (objCasilla?.status?.movil ? objCasilla?.status?.movil : ''))">
                                <button class="btn boton-rosa txBlanco custom-wd" [disabled]="(!objCasilla?.status?.scanner && !objCasilla?.status?.movil)">
                                    <i class="far fa-file" aria-hidden="true"></i>
                                    <i class="fas" aria-hidden="true" [ngClass]="{'fa-print' : objCasilla?.status?.scanner, 'fa-mobile-alt': (objCasilla?.status?.movil && objCasilla?.status?.scanner), 'fa-hourglass': (!objCasilla?.status?.scanner && objCasilla?.status?.movil)}"></i>
                                    Ver
                                </button>
                            </a>
                            <button *ngIf="(!objCasilla?.status?.scanner && !objCasilla?.status?.movil && !objCasilla?.status?.catd)" class="btn boton-rosa txBlanco custom-wd" [disabled]="(!objCasilla?.status?.scanner && !objCasilla?.status?.movil)">
                                <i class="far fa-file" aria-hidden="true"></i>
                                <i class="fas" aria-hidden="true" [ngClass]="{'fa-print' : objCasilla?.status?.scanner, 'fa-mobile-alt': (objCasilla?.status?.movil && objCasilla?.status?.scanner), 'fa-hourglass': (!objCasilla?.status?.scanner && objCasilla?.status?.movil)}"></i>
                                Ver
                            </button>
                        </div>
                    </div>
                </div>
                <!--************************** TARJETAS ESCRITORIO ****************************-->
                <div class="container-fluid d-none d-md-none d-xl-block">
                    <div class="row dis-flex pb-1">
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollLeft()">
                            <i class="fas fa-chevron-left" aria-hidden="true"></i>
                        </div>
                        <div class="col-11">
                            <div #widgetsContent class="row dis-flex justify-s scrollH">
                                <div *ngFor="let tarjeta of objCasilla?.fichas"
                                    class="col-6 col-sm-4 col-md-3 col-lg-2 px-1">
                                    <div class="card-resumen">
                                        <div class="encabezado dis-flex w-100 py-2">
                                            <div class="dis-flex w-100">
                                                <div class="row w-100 dis-flex">
                                                    <img *ngFor="let partido of tarjeta?.imgs" class="img-partido" src="./assets/img/logos-partidos/{{partido?.toLowerCase()}}">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cuerpo dis-flex bgGrisTenue p-2">
                                            <div class="w-50">
                                                <p>Votos</p>
                                                <p class="votos">
                                                    <strong>{{ tarjeta?.total }}</strong>
                                                </p>
                                            </div>
                                            <div class="w-50 dis-flex flex-col align-e pr-3 pr-xl-0">
                                                <!-- <p>Porcentaje</p>
                                                <p>{{ tarjeta?.prc ? tarjeta?.prc : tarjeta?.porcentaje}}</p> -->
                                                <p class="estadistica-label" >&nbsp;</p>
                                                <p class="estadistica-val">&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="boton-scroll dis-flex cursorPointer" (click)="scrollRight()">
                            <i class="fas fa-chevron-right" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <!--************************** TARJETAS MOVIL ****************************-->
                <movil-listado-content 
                    [infoList]="objCasilla?.fichas" 
                    [tipoTarjeta]="11"></movil-listado-content>
                <!--********************************************************************************************************************-->
                <div class="container-fluid">
                    <div class="row dis-flex">
                        <div class="col-md-12">
                            <div class="row dis-flex justify-s">

                            </div>
                        </div>
                    </div>
                </div>

                <app-resumen-votacion
                    disable_simulation="true"
                    [acumulados_num]="objCasilla?.resumen?.acumulados?.total"
                    [acumulados_prc]="objCasilla?.resumen?.acumulados?.prc"
                    [noregistradas_num]="objCasilla?.resumen?.no_registradas?.total"
                    [noregistradas_prc]="objCasilla?.resumen?.no_registradas?.prc"
                    [nulos_num]="objCasilla?.resumen?.nulos?.total"
                    [nulos_prc]="objCasilla?.resumen?.nulos?.prc"
                    [total_num]="objCasilla?.resumen?.total?.total"
                    [total_prc]="objCasilla?.resumen?.total?.prc"
                    [observaciones]="objCasilla?.resumen?.observaciones"
                    [use_observaciones]="true"
                    [hide_prc]="true"
                    [hide_legend]="true"
                ></app-resumen-votacion>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="estadistica-casilla-movil card mt-3 p-3 d-none d-xl-block">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <h4 class="subtitulo-seccion pleca-izquierda">
                                <strong>Estadística de Casilla</strong>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="row">
                        <div class="col">
                            <p class="subtitulo underline">
                                <strong class="cursorPointer">Participación ciudadana con base en la Lista Nominal del Acta PREP Contabilizada</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row my-2">
                        <div class="col-12">
                            <div class="grafica pt-3">
                                <div class="textos" style="display: flex;">
                                    <div class="w-50">
                                        <p class="estadistica-label" >{{objCasilla?.estadistica?.casilla}}</p>
                                        <p class="estadistica-val"><strong>{{objCasilla?.estadistica?.prc}}</strong></p>
                                    </div>
                                    <div class="w-50">
                                        <p class="estadistica-label"  style="text-align: right;"><strong>Total de la Lista Nominal</strong></p>
                                        <p class="estadistica-val" style="text-align: right;"><strong>{{objCasilla?.estadistica?.total}}</strong></p>
                                    </div>
                                </div>
                                <div class="container-interno">
                                        <div class="barra" [ngStyle]="{ 'width.': objCasilla?.estadistica?.prc}"></div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-10">
                            <div class="grafica dis-flex justify-s px-2">
                                <div class="textos" [ngClass]="{'txAzul': objCasilla?.estadistica?.prc < 2}">
                                    <p class="casilla px10">
                                        {{ objCasilla?.estadistica?.casilla }}
                                    </p>
                                    <p class="porcentaje px12">
                                        <strong>
                                            {{ objCasilla?.estadistica?.prc }}
                                        </strong>
                                    </p>
                                </div>
                                <div class="interno" [ngStyle]="{ 'width.%': objCasilla?.estadistica?.prc}">
                                </div>
                            </div>
                        </div>
                        <div class="col-2 dis-flex flex-col align-e pr-2">
                            <p class="lista-nominal">
                                <strong>
                                    Total de la Lista Nominal
                                </strong>
                            </p>
                            <p class="total-nominal">
                                <strong>
                                    {{ objCasilla?.estadistica?.total }}
                                </strong>
                            </p>
                        </div> -->
                    </div>
                    <div class="row my-2">
                        <div class="col-12">
                            <p class="bt-estadistica">El cálculo de la Participación ciudadana puede rebasar el 100% si vota toda la ciudadanía de la Lista Nominal de una casilla; así como las Representaciones de Partidos Políticos.</p>
                        </div>
                    </div>
                </div>
            </div>
            <movil-estadistica-content
                [tipoEstadistica]="1"
                [nombreCasilla]="objCasilla?.estadistica?.casilla"
                [infoPorcentaje]="objCasilla?.estadistica?.prc"
                [listaNominal]="objCasilla?.estadistica?.total"
            ></movil-estadistica-content>
        </div>
    </div>
    <div class="row bkg-gray" #observaciones id="observaciones">
        <div class="col-md-12">
            <div class="estadistica-casilla observacionesActa card mt-3 p-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col">
                            <h4 class="subtitulo-seccion pleca-izquierda">
                                <strong>Observaciones en Acta</strong>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-4">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <h4 class="subtitulo-seccion pleca-izquierda">
                                            <strong class="txRosa2">Contabilizadas</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Ilegible
                                                </strong>
                                            </p>
                                            <p>
                                                La cantidad de votos en algún campo del Acta PREP no puede leerse, tanto en letra como en número.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Sin Dato
                                                </strong>
                                            </p>
                                            <p class="d-none d-xl-block">La cantidad de votos no fue asentada en el Acta PREP ni en letra ni en número en algún campo del Acta PREP.</p>
                                            <p class="d-block d-xl-none">La cantidad de votos no fue asentada en algún campo del Acta PREP.</p>
                                        </div>
                                        <!-- <div class="grupo py-3 mb-2">
                                            <p class="pb-2">
                                                <strong class="d-none d-xl-block">Identificación por Proceso Técnico Operativo</strong>
                                                <strong class="d-block d-xl-none">Identificación por PTO</strong>
                                            </p>
                                            <p>
                                                Los datos del Acta PREP no se identificaron y sé solicitó verificarlos por algún procedimiento válido establecido en el Proceso Técnico Operativo.
                                            </p>
                                        </div> -->
                                        <h4 class="subtitulo-seccion pleca-izquierda pleca-morada mt-5 d-none d-xl-block">
                                            <strong><span class="txMorado">No identificadas -</span> Fuera del catálogo</strong>
                                        </h4>
                                        <div class="grupo py-3 d-none d-xl-block">
                                            <p>
                                                No es posible ubicar el Acta PREP dentro de la lista de Actas de casillas aprobadas por el INE, al presentar alguna omisión, ilegibilidad o error en sus datos de identificación. Para fines de publicación, el Acta se muestra al final del listado de Actas
                                                aprobadas en la Base de Datos.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <h4 class="subtitulo-seccion pleca-izquierda pleca-aqua">
                                            <strong class="txAqua">No Contabilizadas</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Excede Lista Nominal
                                                </strong>
                                            </p>
                                            <p>
                                                La suma de todos los votos en el Acta PREP, excede el número de ciudadanas y ciudadanos en la Lista Nominal de Electores de la casilla, incluido el número de votos emitidos por los representantes de los Partidos Políticos.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Sin Acta
                                                </strong>
                                            </p>
                                            <p class="pb-2">
                                                El Acta PREP no llegó junto con el paquete electoral al Centro de Acopio y Transmisión de Datos (CATD), por algunas de las siguientes causas:
                                            </p>
                                            <p class="">1. Sin Acta por paquete no entregado.</p>
                                            <p class="">2. Sin Acta por casilla no instalada.</p>
                                            <p class="">3. Sin Acta por paquete entregado sin bolsa.</p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Todos los campos ilegibles
                                                </strong>
                                            </p>
                                            <p>
                                                Todos los campos del Acta PREP correspondientes al número de votos, son ilegibles tanto en letra como en número.
                                            </p>
                                        </div>
                                        <div class="grupo py-3">
                                            <p class="pb-2">
                                                <strong>
                                                    Todos los campos vacíos
                                                </strong>
                                            </p>
                                            <p>
                                                Todos los campos del Acta PREP correspondientes al número de votos, se encuentran vacíos tanto en letra como en número.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-12 d-block d-xl-none">
                                        <h4 class="subtitulo-seccion pleca-izquierda pleca-morada mt-2">
                                            <strong><span class="txMorado">No identificadas -</span> Fuera del catálogo</strong>
                                        </h4>
                                        <div class="grupo py-3">
                                            <p>
                                                No es posible ubicar el Acta PREP dentro de la lista de Actas de casillas aprobadas por el INE, al presentar alguna omisión, ilegibilidad o error en sus datos de identificación. La consulta de estas Actas puede hacerse en la Base de Datos del Sistema.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
